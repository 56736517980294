import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'widget-tasks',
  templateUrl: './tasks.component.html'
})

export class TasksComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
