import {
    Directive, ElementRef, OnInit,
    OnChanges, Input, Renderer2 as Renderer,
    HostListener, SimpleChanges
} from '@angular/core';


@Directive ({
    selector: '[formControlFloat]'
})

export class InputFloatDirective implements OnInit, OnChanges {

    @Input('ngModel')  ngModel: any;

    elem: any = this.el.nativeElement;
    constructor (private el: ElementRef, private renderer: Renderer) { }

    @HostListener('blur') onBlur() {
      // console.log('blur event called');
      this.refreshStatus();
    }

    /*@HostListener('input') onInput() {
        console.log('on user input');
    }*/

    ngOnChanges(changes: SimpleChanges): void {
        // console.log('ngOnchanges', changes);
        this.refreshStatus();
    }

    ngOnInit() {
        // console.log('ngOninit called', this.elem.value);
        this.refreshStatus();
    }

    private refreshStatus() {
       // console.log('refreshStatus', this.ngModel, this.elem.value);

      let status = !!(this.ngModel || this.ngModel === 0);
      if (status) this.renderer.addClass(this.elem, 'form-control--active');
      else this.renderer.removeClass(this.elem, 'form-control--active');
    }
}
