import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../shared/services/api.service';

@Component({
  selector: 'app-associate-school',
  templateUrl: './associate-school.component.html',
  styleUrls: ['./associate-school.component.scss']
})
export class AssociateSchoolComponent implements OnInit {
  school_ids: string;
  associated_schools: any;

  constructor(private toastrService: ToastrService, private apiService: ApiService) {

  }

  ngOnInit() {
    this.getAssociateSchools();
  }

  associateSchools() {
    let _self = this;
    let school_ids = [];
    try {
      school_ids = JSON.parse('[' + this.school_ids + ']');
    } catch (e) {
      school_ids = [];
    }
    if (school_ids.length <= 0 || school_ids.length > 3) return this.toastrService
      .error('Please enter at least one or max three school id`s to associate.');

    this.apiService.post('schools/associateSchools', {school_ids: school_ids}, function (err, response) {
      if (err) return _self.toastrService.error((err.code && err.code == 'E_BAD_REQUEST') ? err.data : 'Something went wrong.');
      _self.school_ids = '';
      _self.getAssociateSchools();

    });
  }

  getAssociateSchools() {
    let _self = this;

    this.apiService.get('schools/getAssociateSchools', null, function (err, response) {
      if (err) return _self.toastrService.error('some error occurred in fetching data');
      _self.associated_schools = response.data;
    });
  }


  dissociate(connected_school_id) {
    let _self = this;
    this.apiService.post('schools/dissociate', {connected_school_id: connected_school_id}, function (err, response) {
      if (err) return _self.toastrService.error((err.code && err.code == 'E_BAD_REQUEST') ? err.data : 'Something went wrong.');
      _self.getAssociateSchools();
    });
  }
}
