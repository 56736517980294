<div class="card card-inverse widget-signups">
  <div class="card-header">
    <h2 class="card-title">Most Recent Signups</h2>
    <small class="card-subtitle">Magna cursus malesuada lacinia</small>

    <div class="actions actions--inverse">
      <div dropdown>
        <span dropdownToggle><i class="zmdi zmdi-more-vert"></i></span>
        <div *dropdownMenu class="dropdown-menu dropdown-menu-right">
          <a [routerLink]="['/widgets']" class="dropdown-item">Refresh</a>
          <a [routerLink]="['/widgets']" class="dropdown-item">Manage Widgets</a>
          <a [routerLink]="['/widgets']" class="dropdown-item">Settings</a>
        </div>
      </div>
    </div>

    <div class="widget-signups__chart mt-2">
      <sparkline [options]="signupChartOptions" [dataset]="signupChartData"></sparkline>
    </div>
  </div>

  <div class="widget-signups__list">
    <a *ngFor="let signupData of signupData" [routerLink]="['/widgets']" >
      <div *ngIf="!signupData.avatar" class="avatar-char">{{ signupData.letter }}</div>
      <img *ngIf="signupData.avatar" class="avatar-img" [src]="signupData.avatar" alt="">
    </a>
  </div>
</div>