import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'widget-random-post',
  templateUrl: './random-post.component.html'
})
export class RandomPostComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
