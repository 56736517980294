<section class="content">
  <header class="content__title">
    <h1>Tabs</h1>
    <small>Add quick, dynamic tab functionality to transition through panes of local content, even via dropdown menus.</small>

    <div class="actions">
      <a class="actions__item zmdi zmdi-trending-up" [routerLink]="['/components/tabs']"></a>
      <a class="actions__item zmdi zmdi-check-all" [routerLink]="['/components/tabs']"></a>
      <div dropdown>
        <i dropdownToggle class="actions__item zmdi zmdi-more-vert"></i>
        <div *dropdownMenu class="dropdown-menu dropdown-menu-right">
          <a [routerLink]="['/components/tabs']" class="dropdown-item">Refresh</a>
          <a [routerLink]="['/components/tabs']" class="dropdown-item">Manage Widgets</a>
          <a [routerLink]="['/components/tabs']" class="dropdown-item">Settings</a>
        </div>
      </div>
    </div>
  </header>

  <div class="card">
    <div class="card-header">
      <h2 class="card-title">Static tabs</h2>
      <small class="card-subtitle">Basic example with static contents.</small>
    </div>

    <div class="card-block">
      <tabset>
        <tab heading="Fusce">
          <p>Duis arcu tortor, suscipit eget, imperdiet nec, imperdiet iaculis, ipsum. Vestibulum purus quam, scelerisque ut, mollis sed, nonummy id, metus. Nulla sit amet est. Praesent ac massa at ligula laoreet iaculis. Vivamus aliquet elit ac nisl. Nulla porta dolor. Cras dapibus. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus.</p>
          <p>In hac habitasse platea dictumst. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos hymenaeos. Nam eget dui. In ac felis quis tortor malesuada pretium. Phasellus consectetuer vestibulum elit. Duis lobortis massa imperdiet quam. Pellentesque commodo eros a enim. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; In ac dui quis mi consectetuer lacinia. Phasellus a est. Pellentesque commodo eros a enim. Cras ultricies mi eu turpis hendrerit fringilla. Donec mollis hendrerit risus. Vestibulum turpis sem, aliquet eget, lobortis pellentesque, rutrum eu, nisl. Praesent egestas neque eu enim. In hac habitasse platea dictumst.</p>
        </tab>

        <tab heading="Etiam">
          <p>Morbi mattis ullamcorper velit. Etiam rhoncus. Phasellus leo dolor, tempus non, auctor et, hendrerit quis, nisi. Cras id dui. Curabitur turpis. Etiam ut purus mattis mauris sodales aliquam. Aenean viverra rhoncus pede. Nulla sit amet est. Donec mi odio, faucibus at, scelerisque quis, convallis in, nisi. Praesent ac sem eget est egestas volutpat. Cras varius. Morbi mollis tellus ac sapien. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nam ipsum risus, rutrum vitae, vestibulum eu, molestie vel, lacus. Fusce vel dui.</p>
        </tab>

        <tab heading="Vulputate">
          <p>Etiam rhoncus. Phasellus leo dolor, tempus non, auctor et, hendrerit quis, nisi. Cras id dui. Curabitur turpis. Etiam ut purus mattis mauris sodales aliquam. Aenean viverra rhoncus pede. Nulla sit amet est. Donec mi odio, faucibus at, scelerisque quis, convallis in, nisi. Praesent ac sem eget est egestas volutpat. Cras varius. Morbi mollis tellus ac sapien. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nam ipsum risus, rutrum vitae, vestibulum eu, molestie vel, lacus. Fusce vel dui.Morbi mattis ullamcorper velit. Etiam rhoncus. Phasellus leo dolor, tempus non, auctor et, hendrerit quis, nisi. Cras id dui. Curabitur turpis. Etiam ut purus mattis mauris sodales aliquam. Aenean viverra rhoncus pede. Nulla sit amet est. Donec mi odio, faucibus at, scelerisque quis, convallis in, nisi. Praesent ac sem eget est egestas volutpat. Cras varius. Morbi mollis tellus ac sapien. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo.</p>
        </tab>

        <tab heading="Consectetur">
          Nullam id dolor id nibh ultricies vehicula ut id elit. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Maecenas faucibus mollis interdum.
        </tab>
      </tabset>
    </div>
  </div>

  <div class="card">
    <div class="card-header">
      <h2 class="card-title">Justified tab links</h2>
      <small class="card-subtitle">Use property <code>[justified]="true"</code> to enable justified tabs.</small>
    </div>

    <div class="card-block">
      <tabset [justified]="true">
        <tab heading="Fusce">
          <p>Duis arcu tortor, suscipit eget, imperdiet nec, imperdiet iaculis, ipsum. Vestibulum purus quam, scelerisque ut, mollis sed, nonummy id, metus. Nulla sit amet est. Praesent ac massa at ligula laoreet iaculis. Vivamus aliquet elit ac nisl. Nulla porta dolor. Cras dapibus. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus.</p>
          <p>In hac habitasse platea dictumst. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos hymenaeos. Nam eget dui. In ac felis quis tortor malesuada pretium. Phasellus consectetuer vestibulum elit. Duis lobortis massa imperdiet quam. Pellentesque commodo eros a enim. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; In ac dui quis mi consectetuer lacinia. Phasellus a est. Pellentesque commodo eros a enim. Cras ultricies mi eu turpis hendrerit fringilla. Donec mollis hendrerit risus. Vestibulum turpis sem, aliquet eget, lobortis pellentesque, rutrum eu, nisl. Praesent egestas neque eu enim. In hac habitasse platea dictumst.</p>
        </tab>

        <tab heading="Etiam">
          <p>Morbi mattis ullamcorper velit. Etiam rhoncus. Phasellus leo dolor, tempus non, auctor et, hendrerit quis, nisi. Cras id dui. Curabitur turpis. Etiam ut purus mattis mauris sodales aliquam. Aenean viverra rhoncus pede. Nulla sit amet est. Donec mi odio, faucibus at, scelerisque quis, convallis in, nisi. Praesent ac sem eget est egestas volutpat. Cras varius. Morbi mollis tellus ac sapien. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nam ipsum risus, rutrum vitae, vestibulum eu, molestie vel, lacus. Fusce vel dui.</p>
        </tab>

        <tab heading="Vulputate">
          <p>Etiam rhoncus. Phasellus leo dolor, tempus non, auctor et, hendrerit quis, nisi. Cras id dui. Curabitur turpis. Etiam ut purus mattis mauris sodales aliquam. Aenean viverra rhoncus pede. Nulla sit amet est. Donec mi odio, faucibus at, scelerisque quis, convallis in, nisi. Praesent ac sem eget est egestas volutpat. Cras varius. Morbi mollis tellus ac sapien. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nam ipsum risus, rutrum vitae, vestibulum eu, molestie vel, lacus. Fusce vel dui.Morbi mattis ullamcorper velit. Etiam rhoncus. Phasellus leo dolor, tempus non, auctor et, hendrerit quis, nisi. Cras id dui. Curabitur turpis. Etiam ut purus mattis mauris sodales aliquam. Aenean viverra rhoncus pede. Nulla sit amet est. Donec mi odio, faucibus at, scelerisque quis, convallis in, nisi. Praesent ac sem eget est egestas volutpat. Cras varius. Morbi mollis tellus ac sapien. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nam ipsum risus, rutrum vitae, vestibulum eu, molestie vel, lacus. Fusce vel dui.</p>
        </tab>

        <tab heading="Consectetur">
          Nullam id dolor id nibh ultricies vehicula ut id elit. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Maecenas faucibus mollis interdum.
        </tab>
      </tabset>
    </div>
  </div>

  <div class="card">
    <div class="card-header">
      <h2 class="card-title">Theming</h2>
      <small class="card-subtitle">Use optional modifier classes to change the active state border color.</small>
    </div>

    <div class="card-block">
      <div class="row">
        <div class="col-md-6">
          <tabset class="tab-container--red">
            <tab heading="Fusce">Donec ullamcorper nulla non metus auctor fringilla.</tab>

            <tab heading="Etiam">Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</tab>

            <tab heading="Vulputate">Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.</tab>

            <tab heading="Consectetur">Maecenas sed diam eget risus varius blandit sit amet non magna.</tab>
          </tabset>

          <br>

          <tabset class="tab-container--green">
            <tab heading="Fusce">Donec ullamcorper nulla non metus auctor fringilla.</tab>

            <tab heading="Etiam">Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</tab>

            <tab heading="Vulputate">Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.</tab>

            <tab heading="Consectetur">Maecenas sed diam eget risus varius blandit sit amet non magna.</tab>
          </tabset>

          <br>
        </div>

        <div class="col-md-6">
          <tabset class="tab-container--amber">
            <tab heading="Fusce">Donec ullamcorper nulla non metus auctor fringilla.</tab>

            <tab heading="Etiam">Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</tab>

            <tab heading="Vulputate">Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.</tab>

            <tab heading="Consectetur">Maecenas sed diam eget risus varius blandit sit amet non magna.</tab>
          </tabset>

          <br>

          <tabset class="tab-container--black">
            <tab heading="Fusce">Donec ullamcorper nulla non metus auctor fringilla.</tab>

            <tab heading="Etiam">Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</tab>

            <tab heading="Vulputate">Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.</tab>

            <tab heading="Consectetur">Maecenas sed diam eget risus varius blandit sit amet non magna.</tab>
          </tabset>
        </div>
      </div>
    </div>
  </div>
</section>
