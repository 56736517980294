import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {HeaderComponent} from './header.component';
import {NavigationTriggerComponent} from './navigation-trigger/navigation-trigger.component';
import {SearchComponent} from './search/search.component';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {FormsModule} from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    BsDropdownModule.forRoot(),
  ],
  declarations: [
    HeaderComponent,
    NavigationTriggerComponent,
    SearchComponent
  ],
  exports: [
    HeaderComponent,
    NavigationTriggerComponent,
    SearchComponent
  ]
})
export class HeaderModule { }
