<section class="content fixed-height">


  <div class="card" *ngIf="associated_schools && associated_schools.length > 0">
    <div class="card-header-black">
      <h2 class="card-title">Associated Schools</h2>
    </div>
    <div class="card-block">
      <div class="row">
        <div class="col-12">
          <div class="table-responsive">
            <table class="table table-sm">
              <thead>
              <tr>
                <th class="text text-gray-dark">School Id</th>
                <th class="text text-gray-dark">School Name</th>
                <th class="text-center text-gray-dark">Action</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let school of associated_schools">
                <td> {{school.connected_school_id}}</td>
                <td> {{school.school_name}}</td>
                <td class="text-center">
                  <button class="btn btn-danger" (click)="dissociate(school.connected_school_id)">Dissociate</button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <form ngNativeValidate (submit)="associateSchools()">
    <div class="card info-group">
      <div class="card-header-black">
        <h2 class="card-title">Associate Preschool</h2>
      </div>
      <div class="card-block">

        <div class="row">

          <div class="col-sm-12">
            <div class="form-group form-group--float">
              <input name="name" type="text" [(ngModel)]="school_ids" class="form-control"
                     formControlFloat required>
              <label>Enter School Id's (
                <small>Enter only three preschool id's using comma separation. More than three preschool id's not
                  allowed.
                </small>
                )</label>
              <i class="form-group__bar"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 text-center">
        <button type="submit" class="btn btn-lg btn-success center-block">
          <i class="zmdi zmdi-edit"></i>&nbsp;&nbsp; Associate
        </button>
      </div>
    </div>
  </form>
</section>
