import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../shared/services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {UserModel} from '../../shared/services/user.model';

@Component({
  selector: 'app-school-register',
  templateUrl: './school-register.component.html',
  styleUrls: ['./school-register.component.scss']
})
export class SchoolRegisterComponent implements OnInit {

  user: UserModel;
  SchoolTypes: any[];
  school_name: string;
  school_type = '';

  constructor(private activatedRoute: ActivatedRoute,
              private apiService: ApiService,
              private router: Router,
              private toastrService: ToastrService) {
  }

  ngOnInit() {
    this.initSchoolTypes();
  }

  initSchoolTypes() {
    this.SchoolTypes = ApiService.getSchoolTypes();
  }

  onCreateSubmit() {
    let _self = this;
    const params = {school_name: this.school_name, school_type: this.school_type};
    this.apiService.post('admin/createschool', params, function (err, response) {
      console.log('onCreateSubmit: ', err, response);
      if (!err && response.code === 'OK') {
        _self.toastrService.success('created Successfully');
        _self.router.navigate(['./school/info']);
        sessionStorage.removeItem('admin_school_id');
        location.reload();
      }
      else _self.toastrService.error('Something went wrong!');
    });
  }


}
