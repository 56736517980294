
<div class="error-container">
    <div class="header-error">
      <h1>
        <a href=""><img src="../../../../assets/demo/img/edustoke.png" alt="Edustoke Logo Image"></a>
      </h1>
    </div>
    <div class="error-text-box">
      <h1>404</h1>
      <h2>Not Found</h2>
      <p>"We can't seem to find the page you are looking for"</p>
    </div>
    <button class="btn btn-secondary" routerLink='/'>Back To Home</button>
  </div>
