import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'widget-pie-charts',
  templateUrl: './pie-charts.component.html',
  styleUrls: ['./pie-charts.component.scss']
})
export class PieChartsComponent implements OnInit {
  constructor() {}

  ngOnInit() {
  }

}
