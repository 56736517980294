import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../../shared/services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import * as _ from 'lodash';

declare let window: Window;

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
    messagesData: Array<any>;
    tasksData: Array<any>;
    WEBSITE_URL: string;

    @Input()
    isSchool = false;

    UserRole: string;
    exiting_credit: any;
    chainList = [];
    fullChainList = [];
    chainCityList = [];
    selectedSchoolChain;
    public review_notification_count: number | null;
    public registration_notification_count: number | null;
    public review_notification: any;
    public registration_notification: any;
    searchStatus: boolean;
    public currentRole: string | null;
    private paymentpage: boolean;
    private credit_subscription;

    constructor(private ref: ChangeDetectorRef, public apiService: ApiService,
                private activatedRoute: ActivatedRoute, private router: Router,
                private toastrService: ToastrService) {

        this.exiting_credit = 0;
        this.review_notification_count = 0;

        this.currentRole = (sessionStorage.getItem('current_role')) ? sessionStorage.getItem('current_role') : this.apiService.user.role;

        // this.credit_subscription = this.apiService.exiting_credit.subscribe(credit => this.onCreditUpdate(credit));

        this.paymentpage = this.router.url.includes('payment');

        this.messagesData = [
            {
                image: './assets/demo/img/profile-pics/1.jpg',
                name: 'David Belle',
                message: 'Cum sociis natoque penatibus et magnis dis parturient montes'
            },
            {
                image: './assets/demo/img/profile-pics/2.jpg',
                name: 'Jonathan Morris',
                message: 'Nunc quis diam diamurabitur at dolor elementum, dictum turpis vel'
            },
            {
                image: './assets/demo/img/profile-pics/6.jpg',
                name: 'Fredric Mitchell Jr.',
                message: 'Phasellus a ante et est ornare accumsan at vel magnauis blandit turpis at augue ultricies'
            },
            {
                image: './assets/demo/img/profile-pics/4.jpg',
                name: 'Glenn Jecobs',
                message: 'Ut vitae lacus sem ellentesque maximus, nunc sit amet varius dignissim, dui est consectetur neque'
            },
            {
                image: './assets/demo/img/profile-pics/5.jpg',
                name: 'Bill Phillips',
                message: 'Proin laoreet commodo eros id faucibus. Donec ligula quam, imperdiet vel ante placerat'
            }
        ];

        this.tasksData = [
            {
                name: 'HTML5 Validation Report',
                completed: 95,
                color: ''
            }, {
                name: 'Google Chrome Extension',
                completed: '80',
                color: 'success'
            }, {
                name: 'Social Intranet Projects',
                completed: '20',
                color: 'warning'
            }, {
                name: 'Bootstrap Admin Template',
                completed: '60',
                color: 'danger'
            }, {
                name: 'Youtube Client App',
                completed: '80',
                color: 'info'
            }
        ];
    }

    ngOnInit() {
        this.UserRole = this.apiService.user.role;
        this.currentRole = (sessionStorage.getItem('current_role')) ? sessionStorage.getItem('current_role') : this.apiService.user.role;
        this.WEBSITE_URL = this.apiService.WEBSITE_URL;
        // this.getCurrentCredit();
        if (this.UserRole === 'chainadmin') {
            this.selectedSchoolChain = this.apiService.getSchoolId();
            this.getSchoolChainList();
        }
        this.getReviewNotificationCount();
        this.getRegistrationNotificationCount();
    }

    ngOnDestroy() {
        // this.credit_subscription.unsubscribe();
        // this.ref.detach();
    }

    onLogout() {
        this.apiService.logout();
        this.router.navigate(['./login'], {relativeTo: this.activatedRoute});
    }

    previewSchool() {
        // http://dev.edustoke.com/admin/school/update-url-byid.php?school_id=11155&api_key=ASDFASDFCJD7jdjenj3**jdndeje()kdj%3C%3Eh78
        const school_url = sessionStorage.getItem('school_url') + '?preview=true&_=' + new Date().getTime();
        if (school_url) window.open(school_url, '_blank');
        else this.toastrService.error('Preview Failed: Try republishing school Or Contact Us');
    }

    getCurrentCredit() {
        let _self = this;
        this.apiService.get('subscriptions/getCredit', null, (err, result) => {
            console.log('err', err, 'result', result);

            if (result.data) {
                _self.exiting_credit = result.data.credit;
            }
        });
    }

    getSchoolChainList() {
        let _self = this;
        this.apiService.get('schoolchains/list', null, (err, result) => {
            console.log('err', err, 'result', result);

            if (result.data) {
                _self.fullChainList = result.data;
                _self.chainList = result.data;
                result.data.forEach(school => {
                    _self.chainCityList.push({id: school.cityid, name: school.city});
                });
                _self.chainCityList = _.uniqWith(_self.chainCityList, _.isEqual) || [];
            }
        });
    }

    schoolChainCitySelected(event) {
        const selectedCityId = event.target.value;
        if (!selectedCityId || selectedCityId === 'null') return this.chainList = this.fullChainList;

        this.chainList = this.fullChainList.filter((school) => {
            return school.cityid.toString() === selectedCityId.toString();
        });
        this.selectedSchoolChain = null;
        // console.log('schoolChainCitySelected', selectedCityId, this.chainList, this.fullChainList);

    }

    schoolChainSelected(event) {
        const selectedSchoolId = event.target.value;
        this.updateAdminSchoolId(selectedSchoolId);
    }

    updateAdminSchoolId(school_id) {
        let self = this;
        this.apiService.put('admin/updateschool', {school_id: school_id}, function (err, res) {
            if (!err && res.code === 'OK') {
                sessionStorage.setItem('school_url', '');
                sessionStorage.setItem('admin_school_id', school_id);
                location.reload();
            } else self.toastrService.error('An error occurred!');
        });
    }

    onCreditUpdate(credit: any): void {
        // this.exiting_credit = credit;
        // if (!this.ref['destroyed']) {
        //     this.ref.detectChanges();
        // }

    }

    getReviewNotificationCount() {
        let _self = this;
        this.apiService.get('rateandreview/notification_count', null, function (err, response) {
            if (err) {
                _self.toastrService.error('Error occurred');
                _self.review_notification_count = 0;
                return;
            } else {
                _self.review_notification_count = (parseInt(response.data)) ? parseInt(response.data) : 0;
            }
        });
    }

    getReviewNotification() {
        let _self = this;
        this.apiService.get('rateandreview/notification', null, function (err, response) {
            if (err) {
                _self.toastrService.error('Error occurred');
                return;
            } else {
                _self.getReviewNotificationCount();
                _self.review_notification = response.data;
            }
        });
    }

    navigateToReviews(review) {
        let current_school_id = null;
        try {
            current_school_id = sessionStorage.getItem('admin_school_id');
        } catch (e) {
            current_school_id = null;
        }
        sessionStorage.setItem('admin_school_id', review.school_id);

        if (window.location.pathname.includes('/school/review') && parseInt(review.school_id) === parseInt(current_school_id)) {
            this.router.navigate(['school/review/' + review.id]);
        } else {
            window.location.pathname = 'school/review/' + review.id;

        }
    }

    getRegistrationNotificationCount() {
        let _self = this;
        this.apiService.get('admissionapplications/registration_notification_count', null, function (err, response) {
            if (err) {
                _self.toastrService.error('Error occurred');
                _self.registration_notification_count = 0;
                return;
            } else {
                _self.registration_notification_count = (parseInt(response.data)) ? parseInt(response.data) : 0;
            }
        });
    }

    getRegistrationNotification() {
        let _self = this;
        this.apiService.get('admissionapplications/registration_notification', null, function (err, response) {
            if (err) {
                _self.toastrService.error('Error occurred');
                return;
            } else {
                _self.getRegistrationNotificationCount();
                _self.registration_notification = response.data;
            }
        });
    }
}
