<div class="card card-inverse widget-pie">

  <div class="widget-pie__item">
    <div EasyPieChart class="chart-pie" [size]="80" data-percent="50">
      <span class="chart-pie__value">92</span>
    </div>
    <div class="widget-pie__title">Email<br> Scheduled</div>
  </div>

  <div class="widget-pie__item">
    <div EasyPieChart class="chart-pie" [size]="80" data-percent="11">
      <span class="chart-pie__value">11</span>
    </div>
    <div class="widget-pie__title">Email<br> Bounced</div>
  </div>

  <div class="widget-pie__item">
    <div EasyPieChart class="chart-pie" [size]="80" data-percent="52">
      <span class="chart-pie__value">52</span>
    </div>
    <div class="widget-pie__title">Email<br> Opened</div>
  </div>

  <div class="widget-pie__item">
    <div EasyPieChart class="chart-pie" [size]="80" data-percent="44">
      <span class="chart-pie__value">44</span>
    </div>
    <div class="widget-pie__title">Storage<br>Remaining</div>
  </div>

  <div class="widget-pie__item">
    <div EasyPieChart class="chart-pie" [size]="80" data-percent="78">
      <span class="chart-pie__value">78</span>
    </div>
    <div class="widget-pie__title">Web Page<br> Views</div>
  </div>

  <div class="widget-pie__item">
    <div EasyPieChart class="chart-pie" [size]="80" data-percent="32">
      <span class="chart-pie__value">32</span>
    </div>
    <div class="widget-pie__title">Server<br> Processing</div>
  </div>

</div>