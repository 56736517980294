import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'widget-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  constructor() {}

  ngOnInit() {
  }
}
