<div class="card">
    <div class="card-header">
        <h2 class="card-title">Ongoing Tasks</h2>
        <small class="card-subtitle">Maecenas seddiam eget risusvarius blandit</small>
    </div>

    <div class="list-group">
        <a href="" class="list-group-item list-group-item-action">
            <p class="list-group-item-heading">HTML5 Validation Report</p>
            <progress class="progress" value="95" max="100"></progress>
        </a>
        <a href="" class="list-group-item list-group-item-action">
            <p class="list-group-item-heading">Google Chrome Extension</p>
            <progress class="progress progress-success" value="80" max="100"></progress>
        </a>
        <a href="" class="list-group-item list-group-item-action">
            <p class="list-group-item-heading">Social Intranet Projects</p>
            <progress class="progress progress-info" value="20" max="100"></progress>
        </a>
        <a href="" class="list-group-item list-group-item-action">
            <p class="list-group-item-heading">Bootstrap Admin Template</p>
            <progress class="progress progress-warning" value="60" max="100"></progress>
        </a>
        <a href="" class="list-group-item list-group-item-action">
            <p class="list-group-item-heading">Youtube Client App</p>
            <progress class="progress progress-danger" value="80" max="100"></progress>
        </a>

        <a href="" class="view-more">View All Tasks</a>
    </div>
</div>