import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ApiService} from '../../../services/api.service';

@Component({
  selector: 'app-shared-activity-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  activities: any;
  role: any;
  activity_tags: any;

  constructor(private apiService: ApiService, private router: Router) {
    this.activity_tags = this.apiService.APPLICATION_TAGS;
  }

  ngOnInit() {
    this.getAllActivities();
    if (sessionStorage.getItem('current_role')) {
      try {
        this.role = sessionStorage.getItem('current_role');
      } catch (e) {
        this.role = this.apiService.user.role;
      }
    }

  }


  createNewActivity() {
    const _self = this;
    // localStorage.removeItem('current_activity');
    // this.router.navigate([(this.role === 'activity') ? 'activity/detail' : 'school/activities/detail']);

    this.apiService.post('activities/createorupdate', {type: 'SUMMERCAMP'}, (err, response) => {
      if (err) return;
      console.log('createNewActivity', response.data.activity_id);
      localStorage.setItem('current_activity', response.data.activity_id);
      _self.getActivity.call(_self, response.data.activity_id);
    });
  }

  getAllActivities() {
    let _self = this;
    this.apiService.get('activities', null, (err, response) => {
      if (err) return;
      _self.activities = response.data;

      _self.activities = _self.activities.map(activity => {
        if (activity && 'tags' in activity && activity.tags && activity.tags.length >0) {
          const tags = _self.activity_tags.filter(tagObj=> activity.tags.includes(tagObj.id));
          activity.tags = tags.map(({value}) => value).join(', ');
        }
        return activity;
      });

    });
  }

  getActivity(activity_id) {
    this.router.navigate([(this.role === 'activity') ? 'activity/detail/' + activity_id : 'school/activities/detail/' + activity_id]);
  }

  manageAct(activity_id) {
    this.router.navigate([(this.role === 'activity') ? 'activity/application/' + activity_id : 'school/activity/application/' + activity_id]);
  }

}
