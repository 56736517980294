import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';

import 'rxjs/add/operator/map';
import {BehaviorSubject, Subject} from 'rxjs';

import {ApiService} from '../../../services/api.service';
import {ToastrService} from 'ngx-toastr';
import {NgProgress as NgProgress, NgProgressRef} from 'ngx-progressbar';
import {NavigationEnd, Router, ActivatedRoute} from '@angular/router';
import {WindowRef} from '../../../services/WindowRef';
import {DataTableDirective} from "angular-datatables";

declare let window: Window;

@Component({
  selector: 'app-shared-activity-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss'],
  providers: [WindowRef]
})
export class ApplicationsComponent implements OnInit, OnDestroy {

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};

  rzp: any;

  dtTrigger: Subject<any> = new Subject();

  private current_activity: any;

  application_selected_to_claim = [];

  progressRef: NgProgressRef = this.progress.ref();

  plp = 400;

  rzpOptions = {
    key: this.apiService.getPaymentKeyId(),
    amount: this.amountCalculator(this.plp),
    name: 'Edustoke',
    description: '',
    image: 'https://www.edustoke.com/images/logo.png',
    handler: this.onPaymentSuccess.bind(this),
    prefill: {
      name: '',
      email: '',
      phone: ''
    },
    notes: {
      address: 'Hello World'
    },
    theme: {
      color: '#02b3a2;'
    }
  };
  applications: any = [];


  constructor(private activatedRoute: ActivatedRoute, private apiService: ApiService, private progress: NgProgress,
              private winRef: WindowRef, private toastrService: ToastrService, private router: Router,
              private ref: ChangeDetectorRef) {

    router.events.subscribe((router_events) => {
      if (router_events instanceof NavigationEnd) {
        this.current_activity = this.activatedRoute.snapshot.params['id'];
      }
    });
  }

  ngOnInit(): void {
    this.dtOptions = {responsive: true};

    this.getAllApplications();

  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  public onPaymentSuccess(paymentResponse): void {
    let _self = this;
    const {razorpay_payment_id = null} = paymentResponse || {};
    if (!razorpay_payment_id) return;
    this.apiService.post('activityapplications/buyleads', {
      razorpay_payment_id: razorpay_payment_id, application_ids: this.application_selected_to_claim
    }, (error, response) => {
      const {code = null, data: {claimedResponse = []} = {}} = response || {};
      if (!error && code === 'OK') {
        _self.progressRef.complete();
        _self.application_selected_to_claim = _self.application_selected_to_claim.filter(elm => {
          let [{claimed}] = claimedResponse.filter(({id}) => elm === id);
          return !claimed;
        });
      } else {
        _self.toastrService.error(error.data || 'Some error Occurred');
      }

      this.getAllApplications();

    });
  }

  getAllApplications() {
    let _self = this;
    this.apiService.get('activityapplications', {activity_id: this.current_activity}, function (err, resp) {
      if (err) return _self.toastrService.error('Something went wrong.');

      if (!resp.data) {
        _self.toastrService.error('No data found.');
      } else {
        _self.applications = resp.data;

        if (_self.dtElement && _self.dtElement.dtInstance) {
          _self.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            _self.dtTrigger.next();
            _self.ref.detectChanges();
          });
        } else {
          _self.dtTrigger.next();
        }

      }
    });
  }

  viewApplication(application_id) {
    let url = this.apiService.WEBSITE_URL + '/summercamp-new/view/activity-pdf.php?application_id=' + application_id;
    window.open(url, '_blank');
  }

  downloadApplication(application_id) {
    let url = this.apiService.WEBSITE_URL + '/summercamp-new/view/activity-pdf.php?application_id=' + application_id + '&download=1';
    window.open(url, '_blank');
  }

  buyLeadApplication(application_id) {
    this.application_selected_to_claim = [];
    this.application_selected_to_claim.push(application_id);
    this.rzp = new this.winRef.nativeWindow.Razorpay(this.rzpOptions);
    this.rzp.open();
  }

  buyAllLeadApplication() {
    this.rzpOptions.amount = this.amountCalculator(this.application_selected_to_claim.length * this.plp);
    this.rzp = new this.winRef.nativeWindow.Razorpay(this.rzpOptions);
    this.rzp.open();
  }

  updateSelectedApplications(application_id) {
    if (this.application_selected_to_claim.indexOf(application_id) >= 0) {
      this.application_selected_to_claim = this.application_selected_to_claim.filter(e => e !== application_id);
    } else {
      this.application_selected_to_claim.push(application_id);
    }

    this.ref.detectChanges();
  }


  amountCalculator(plp) {
    let amount = plp * 100;  // 100 paise = INR 1
    let tax = (amount * 18) / 100;

    return amount + tax;
  }

}
