import {RouterModule, Routes} from '@angular/router';
import {ErrorComponent} from './shared/components/error/error.component';
import {AuthGuard} from './auth/auth.guard';

const ROUTES: Routes = [
  {path: '', redirectTo: 'school', pathMatch: 'full'},
  /* { path: '', loadChildren: './website/website.module#WebsiteModule' },*/
  {path: 'school', loadChildren: () => import('./school/school.module').then(m => m.SchoolModule), canActivate: [AuthGuard]},

  {path: 'parent', loadChildren: () => import('./parent/parent.module').then(m => m.ParentModule), canActivate: [AuthGuard]},

  {path: 'activity', loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule), canActivate: [AuthGuard]},

  {path: ':role/auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)},
  // Eg. parent/login -> /parent/auth/login
  {path: ':role/login', redirectTo: '/:role/auth/login', pathMatch: 'full'},
  {path: ':role/register', redirectTo: '/:role/auth/register'},

  // { path: '**', redirectTo: '/' },
  {path: '**', component: ErrorComponent}
];

export const Routing = RouterModule.forRoot(ROUTES);

