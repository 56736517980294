import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../shared/services/api.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';

@Component({
  selector: 'app-activity-register',
  templateUrl: './activity-register.component.html',
  styleUrls: ['./activity-register.component.scss']
})
export class ActivityRegisterComponent implements OnInit {
  activity: any ;
  activity_types: Array<string>;

  constructor(private apiService: ApiService, private toastrService: ToastrService, private  router: Router) {
    this.activity_types = ApiService.getActivityTypes();
    this.activity = {};
  }

  ngOnInit() {
  }

  onCreateSubmit() {
    let _self = this;

    this.apiService.post('admin/createactivity', this.activity, function (err, response) {
      console.log('onCreateSubmit: ', err, response);
      if (!err && response.code === 'OK') {
        _self.toastrService.success('created Successfully');
        sessionStorage.setItem('current_role', response.data.user['role']);
        localStorage.setItem('activity_owner', response.data.user['id']);
        localStorage.setItem('current_activity', response.data.activity['id']);
        _self.router.navigate(['./activity/info']);
      } else {
        _self.toastrService.error(err.data || err.message || 'Something went wrong!');
      }
    });
  }

}
