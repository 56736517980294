<div class="search form-group" [class.search--toggled]="searchStatus">
  <div class="search__inner">
    <i class="zmdi zmdi-arrow-back search__close" (click)="closeSearch()" (blur)="closeSearch()"></i>
    <input type="text" placeholder="Search..." (keyup)="getSchoolSuggestions()"
           [(ngModel)]="schoolName" autocomplete='off'
           [class.name_selected]="hasSuggestionSelected">

    <!--Search Suggestion-->
    <div class="suggestion-box" [ngClass]="(schoolSuggestions.length > 0) ? 'box_open': 'box_close'">
      <ul class="suggestion-results">
        <li class="school_name" *ngFor="let suggestion of schoolSuggestions;"
            (click)="onSchoolSuggestionSelected(suggestion)">
          {{ suggestion.name }} <br>
          <small class="school_address">{{ suggestion.address1 }}</small>
          <div>
            <span class="badge" [ngClass]="{'badge-success': suggestion.status, 'badge-danger': !suggestion.status}">{{(suggestion.status)?'PUBLISHED':'UNPUBLISHED'}}</span>
            <span class="badge"
                  [ngClass]="{'badge-success': (suggestion.main_status == 'approved' || suggestion.main_status == ''), 'badge-danger': (suggestion.main_status == 'rejected' || suggestion.main_status == 'deleted'), 'badge-warning': (suggestion.main_status == 'pending')}">
              {{(suggestion.main_status == 'approved'  ||suggestion.main_status == '')?'APPROVED':(suggestion.main_status == 'rejected')?'REJECTED':(suggestion.main_status == 'pending')?'PENDING':''}}
              {{suggestion.main_status == 'deleted' ? 'DELETED' : ''}}
            </span>
          </div>
        </li>
      </ul>
    </div>
  </div>

</div>
