<section class="content fixed-height">
  <form ngNativeValidate (submit)="onCreateSubmit()">
    <div class="card info-group">
      <div class="card-header-black">
        <h2 class="card-title">Create New Activity</h2>
      </div>
      <div class="card-block">

        <div class="row">

          <div class="col-sm-6">
            <div class="form-group form-group--float">
              <input name="email" type="email" class="form-control" formControlFloat
                     [(ngModel)]="activity.owner_email" required>
              <label>Owner E-mail * </label>
              <i class="form-group__bar"></i>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="form-group form-group--float">
              <input name="name" type="text" class="form-control" formControlFloat
                     [(ngModel)]="activity.owner_name" required>
              <label>Owner Name *</label>
              <i class="form-group__bar"></i>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="form-group form-group--float">
              <input name="phone" type="text" class="form-control" formControlFloat
                     [(ngModel)]="activity.owner_phone"
                     required pattern="^[^_]*$">
              <label>Owner Phone *</label>
              <i class="form-group__bar"></i>
            </div>
          </div>

        </div>
        <div class="row">
          <div class="col">
            <hr/>
          </div>
        </div>
        <div class="row">

          <div class="col-sm-6">
            <div class="form-group form-group--select">
              <label>Activity Type *</label>
              <div class="select">
                <select name="type" [(ngModel)]="activity.type" class="form-control" required>
                  <option *ngFor="let type of activity_types" [selected]="type == activity.type" [value]="type">
                    {{(type && type !== '')?type:'Select Activity Type'}}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="form-group form-group--float">
              <input name="title" type="text" [(ngModel)]="activity.title" class="form-control" formControlFloat
                     required>
              <label>Activity Name</label>
              <i class="form-group__bar"></i>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 text-center">
        <button type="submit" class="btn btn-lg btn-success center-block">
          <i class="zmdi zmdi-edit"></i>&nbsp;&nbsp; CREATE
        </button>
      </div>
    </div>
  </form>
</section>
