<section class="content fixed-height">

  <div class="card">
    <div class="card-header">
      <h2 class="card-title">Applications</h2>
      <hr class="mb-0"/>
      <!--<small class="card-subtitle">Native Angular2 directives for Tables with sorting, filtering, paging...</small>-->
    </div>
    <div class="card-block">
      <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
             class="table table-striped table-bordered hover mb-0">
        <thead>
        <tr>
          <th>Activity Id</th>
          <th>Activity Name</th>
          <th>Applicant Name</th>
          <th>Phone</th>
          <th>Email</th>
          <th>Ends On</th>
          <th>Action</th>
          <th>Select</th>
          <!--<th>View</th>
          <th>Download</th>-->
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let application of applications">
          <td>{{ application?.activity_id }}</td>
          <td>
            <div class="text-truncate" data-toggle="tooltip" title="{{ application?.title }}"
                 style="max-width: 150px;">{{ application?.title }}</div>
          </td>
          <td>{{ application?.name }}</td>
          <td>{{ application?.contact }}</td>
          <td>
            <div class="text-truncate" data-toggle="tooltip" title="{{ application?.email }}"
                 style="max-width: 150px;">{{ application?.email }}</div>
          </td>
          <td>{{ application.end_date| customDate:'dd-MM-yyyy, h:mm a' }}</td>
          <td class="text-center">
            <button *ngIf="application?.claimed === 0" class="btn btn-success"
                    (click)="buyLeadApplication(application?.id)">Buy
            </button>
          </td>
          <!--<td class="text-center">
            <button class="btn btn-success" (click)="viewApplication(application?.id)">
              <i class="zmdi zmdi-eye"></i>
            </button>
          </td>
          <td class="text-center">
            <button class="btn btn-outline-success" (click)="downloadApplication(application?.id)">
              <i class="zmdi zmdi-download"></i>
            </button>
          </td>-->
          <td>
            <div class="row" *ngIf="application?.claimed === 0">
              <label class="custom-control custom-checkbox">
                <input type="checkbox" name="activity_application" [value]="application?.id"
                       [checked]="application_selected_to_claim.indexOf(application?.id) >= 0"
                       (change)="updateSelectedApplications(application?.id)"
                       class="custom-control-input">
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">Select</span>
              </label>
            </div>
          </td>
        </tr>
        </tbody>
      </table>

      <div class="row">
        <div class="col">
          <button class="btn btn-primary pull-right" (click)="buyAllLeadApplication()"
                  [disabled]="application_selected_to_claim.length <= 0">Buy All Selected
          </button>
        </div>
      </div>
    </div>
  </div>

</section>
