<div class="card">
  <img class="card-img-top" src="./assets/demo/img/widgets/note.png" alt="Card image cap">
  <div class="card-header">
    <h2 class="card-title">Pellentesque Ligula Fringilla</h2>
    <div class="card-subtitle">by Malinda Hollaway on 19th June 2015 at 09:10 AM
    </div>
  </div>
  <div class="card-block">
    <p>Donec ullamcorper nulla non metus auctor fringilla. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Vestibulum id ligula porta felis euismod semper. Nulla vitae elit libero, a pharetra.</p>
    <a href="" class="view-more text-left">View Article...</a>
  </div>
</div>