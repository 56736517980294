import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import * as _ from 'lodash';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../../services/api.service';

import * as asc from 'async';

declare let window: Window;
declare var google: any;

@Component({
  selector: 'app-shared-activity-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit, AfterViewInit {
  age_range = [];
  activity_types: Array<string>;
  activity_subtypes: Array<string>;
  activity: any = {};
  imageUploadUrl = '/api/activityimages/uploadactivityimage';
  authHeader: { [name: string]: any } = {'Authorization': 'Bearer ' + localStorage.getItem('access_token')};
  state = {data: '', options: ''};
  city = {data: '', options: ''};
  activity_gallery: any = [];
  public get_img_by_type_called = {};

  gallery_img_limit: number;
  cover_img_limit: number;
  gallery_img_length: number;
  coverImage: any;
  galleryImages: any;
  default_activity_img_limit: number;
  default_cover_img_limit: number;
  public role: string;
  activity_owner: any;
  defaultSelect2Options: any = {
    dropdownAutoWidth: true, width: '100%',
    containerCssClass: 'select2-selection--alt',
    dropdownCssClass: 'select2-dropdown--alt'
  };
  @ViewChild('ActivityMap') ActivityMap: ElementRef;
  @ViewChild('activity_address') activity_address: ElementRef;
  @ViewChild('activity_address_pin') activity_address_pin: ElementRef;
  @ViewChild('activity_lat') activity_lat: ElementRef;
  @ViewChild('activity_lon') activity_lon: ElementRef;
  @ViewChild('activity_locality') activity_locality: ElementRef;
  @ViewChild('activity_gallery') activity_gallery_container: ElementRef;
  activity_gallery_form_data: any;
  activity_cover_pic_form_data: any;

  activity_tags: any;

  activity_tags_for_bind = [];

  constructor(private apiService: ApiService, private router: Router, private toastrService: ToastrService,
              private elementRef: ElementRef, private route: ActivatedRoute, private cdRef: ChangeDetectorRef,
              private renderer: Renderer2) {

    this.activity_types = ApiService.getActivityTypes();
    this.activity_subtypes = ApiService.getActivitySubTypes();
    this.activity['type'] = '';
    this.activity['status'] = 1;
    this.gallery_img_length = 0;
    this.gallery_img_limit = this.default_activity_img_limit = 2;
    this.cover_img_limit = this.default_cover_img_limit = 1;

    this.activity_tags = this.apiService.APPLICATION_TAGS;
    this.activity_tags_for_bind = this.activity_tags.slice();

    router.events.subscribe((router_events) => {
      if (router_events instanceof NavigationEnd) {

        let current_activity = this.route.snapshot.params['id'];

        if (!current_activity) {
          try {
            current_activity = localStorage.getItem('current_activity');
          } catch (e) {
            current_activity = this.route.snapshot.params['id'];
          }
        }

        this.setSafe('id', current_activity);
      }
    });
  }

  ngOnInit() {
    let _self = this;
    asc.waterfall([
      function (callback) {
        _self.initAgeRange();
        callback(null, 1);
      },
      function (paramone, callback) {
        _self.initActivityStates();
        callback(null, 2);
      },
      function (pareamtwo, callback) {
        _self.initActivityCities();
        callback(null, 3);
      },
      function (paramthree, callback) {
        if (sessionStorage.getItem('current_role')) {
          try {
            _self.role = sessionStorage.getItem('current_role');
          } catch (e) {
            _self.role = _self.apiService.user.role;
          }
        }
        if (localStorage.getItem('activity_owner')) {
          try {
            _self.activity_owner = localStorage.getItem('activity_owner');
          } catch (e) {
            _self.activity_owner = _self.apiService.user.id;
          }
        }

        if (_self.getSafe('id') && _self.getSafe('id') > 0) {
          _self.getActivityById();
          _self.getActivityImages();
        } else {
          _self.createOrUpdateActivity();
        }
        callback(null, 4);
      }], function (err, results) {
      _self.activity_gallery_form_data = {imagetype: 60, activity_id: _self.getSafe('id')};
      _self.activity_cover_pic_form_data = {imagetype: 61, activity_id: _self.getSafe('id')};
      if (_self.activity_owner) {
        _self.activity_gallery_form_data['activity_owner'] = _self.activity_owner;
        _self.activity_cover_pic_form_data['activity_owner'] = _self.activity_owner;
      }
    });
  }

  ngAfterViewInit() {
    let _self = this;
    let googleapis;
    googleapis = this.renderer.createElement('script');
    googleapis.type = 'text/javascript';
    googleapis.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDbUSuQ6MivWulRI-HM5BH1q0pSb3Kmxyw&libraries=places';
    this.elementRef.nativeElement.appendChild(googleapis);

    setTimeout(function () {
      _self.initializeActivityAddressMap();
    }, 3000);
  }

  initAgeRange() {
    for (let i = 2; i <= 20; i++) {
      this.age_range.push(i);
    }
  }

  initActivityStates() {
    this.state.options = _.merge(this.defaultSelect2Options, {placeholder: '-- Select Option --'});
    let _self = this;
    this.apiService.get('states', {limit: 200}, function (err, response) {
      _self.state.data = response.data.map(item => {
        return {id: item.id.toString(), text: item.name};
      });
    });
  }

  initActivityCities() {
    this.city.options = _.merge(this.defaultSelect2Options, {placeholder: '-- Select Option --'});
    let _self = this;
    this.apiService.get('cities', {limit: 1000}, function (err, response) {
      _self.city.data = response.data.map(item => {
        return {id: item.id.toString(), text: item.city};
      });
    });
  }

  getGeoCord() {
    return new google.maps.LatLng(this.activity.lat, this.activity.lon);
  }

  createOrUpdateActivity() {
    let _self = this;

    if (!this.getSafe('city') || this.getSafe('city') === '') this.setSafe('city', null);
    if (!this.getSafe('state') || this.getSafe('state') === '') this.setSafe('state', null);
    if (new Date(this.getSafe('start_date')).toString() === 'Invalid Date') this.setSafe('start_date', null);
    if (new Date(this.getSafe('end_date')).toString() === 'Invalid Date') this.setSafe('end_date', null);

    this.activity.tags = this.activity.tags.map(obj => {
      if (typeof obj === 'string') {
        return _self.activity_tags.find(aObj => {
         const {value} = aObj;
         return value === obj;
       });

      } else return obj;
    });

    this.apiService.post('activities/createorupdate', this.activity, (err, response) => {
      if (err) return;

      if (!Boolean(_self.getSafe('status'))) {
        _self.router.navigate([(_self.role === 'activity') ? 'activity/home' : 'school/activities']);
        return;
      }

      _self.activity = response.data;

      if ('tags' in _self.activity && _self.activity.tags && _self.activity.tags.length > 0) {
        const tags_ids = _self.activity.tags;

        const tags_obj = _self.activity_tags.filter(obj => {
          const {id} = obj;
          return tags_ids.indexOf(id) >= 0;
        });

        _self.activity.tags = tags_obj.map(({value}) => value);

        _self.activity_tags_for_bind = _self.activity_tags.filter(({id}) => tags_ids.indexOf(id) < 0);
      }

      _self.setSafe('id', _self.getSafe('activity_id'));

      localStorage.setItem('current_activity', _self.getSafe('id'));

      _self.activity_gallery_form_data = {imagetype: 60, activity_id: _self.getSafe('id')};
      _self.activity_cover_pic_form_data = {imagetype: 61, activity_id: _self.getSafe('id')};
      if (_self.activity_owner) {
        _self.activity_gallery_form_data['activity_owner'] = _self.activity_owner;
        _self.activity_cover_pic_form_data['activity_owner'] = _self.activity_owner;
      }

      // _self.setActivitiesTags();

      // _self.initNgxChips();

      _self.setSafe('start_date', _self.formatDate(_self.getSafe('start_date')));

      _self.setSafe('end_date', _self.formatDate(_self.getSafe('end_date')));

    });
  }

  getActivityById() {
    let _self = this;

  /*  this.apiService.get('Activitytags/findUnique', null, (err, response) => {
      console.log({err, response});
    });*/

    this.apiService.get('activities', {id: this.getSafe('id')}, (err, response) => {
        if (err) return;
        _self.activity = (response.data.length > 0 && response.data[0]) ? response.data[0] : {};

      if ('tags' in _self.activity && _self.activity.tags && _self.activity.tags.length > 0) {
        const tags_ids = _self.activity.tags;

        const tags_obj = _self.activity_tags.filter(obj => {
          const {id} = obj;
          return tags_ids.indexOf(id) >= 0;
        });

        _self.activity.tags = tags_obj.map(({value}) => value);

        _self.activity_tags_for_bind = _self.activity_tags.filter(({id}) => tags_ids.indexOf(id) < 0);
      }

        // _self.initNgxChips();

      // if (!this.getSafe('city')) this.setSafe('city', '');
      // if (!this.getSafe('state')) this.setSafe('state', '');

      _self.setSafe('start_date', _self.formatDate(_self.getSafe('start_date')));

        _self.setSafe('end_date', _self.formatDate(_self.getSafe('end_date')));

      if (_self.role === 'school' && !_self.activity.address) {
        this.getSchoolInfo();
      }

        _self.cdRef.detectChanges();
      }
    )
    ;

  }

  capitalizeWord(string) {
    string = string.toLocaleLowerCase();
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  wordLimitTo(str, length) {
    return str.substring(0, length);
  }

  slugify(str) {

    str = str.toString();
    str = str.replace(/^\s+|\s+$/g, '');

    // Make the string lowercase
    str = str.toLowerCase();

    // Remove accents, swap ñ for n, etc
    let from = 'ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;';
    let to = 'AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------';
    for (let i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    // Remove invalid chars
    str = str.replace(/[^a-z0-9 -]/g, '')
    // Collapse whitespace and replace by -
      .replace(/\s+/g, '-')
      // Collapse dashes
      .replace(/-+/g, '-');

    return str;
  }

  getImageByType(type) {

    // this was called multiple times causing duplicate images in uploadedImagesArray in ng2-image-upload
    if (this.get_img_by_type_called.hasOwnProperty(type)) return [];
    let images = _.filter(this.activity_gallery, {type: type});
    images = images.map((image) => {
      return image['thumb'];
    });
    // console.log('getImageByType', images);
    if (images.length > 0) this.get_img_by_type_called[type] = true;
    return images;
  }

  onUploadFinished(event) {
    if (event.serverResponse.ok) {
      this.gallery_img_length = (this.activity_gallery_container['files'].length) ?
        this.activity_gallery_container['files'].length : this.checkaUploadedVal(60);
    }
  }

  onRemoved(event) {

    for (const value of this.activity_gallery) {
      if (value.thumb !== event.src) continue;
      this.deleteActivityImage(value.id, null);
    }

    if (event.serverResponse)
      this.deleteActivityImage(JSON.parse(event.serverResponse._body).data.id, JSON.parse(event.serverResponse._body).data);
    else return;
  }

  checkaAndUpdateMaxVal(type, limit) {
    if (this.activity_gallery.length > 0) {
      let typeObjs: any = this.activity_gallery.filter((value) => {
        return value.type === type;
      });
      return ((typeObjs.length === 0) ? limit : limit - typeObjs.length);
    }
    return limit;
  }

  checkaUploadedVal(type) {
    if (this.activity_gallery.length > 0) {
      let typeObjs: any = this.activity_gallery.filter((value) => {
        return value.type === type;
      });
      return typeObjs.length;
    }
    return 0;
  }

  initNgxChips() {
    this.getSanitizedTagInput('tags');
    this.getSanitizedTagInput('artsNCrafts');
    this.getSanitizedTagInput('danceNDrama');
    this.getSanitizedTagInput('language');
    this.getSanitizedTagInput('music');
    this.getSanitizedTagInput('language');
    this.getSanitizedTagInput('mathsNSci');
    this.getSanitizedTagInput('gamesNSports');
    this.getSanitizedTagInput('others');
  }

  getSanitizedTagInput(field) {
    let fieldValue = this.getSafe(field);

    if (fieldValue) {
      let checkCondition = fieldValue instanceof Array;
      if (!checkCondition) {
        fieldValue = fieldValue.split(',');
        this.setSafe(field, fieldValue);
      }
    } else {
      this.setSafe(field, []);
    }
  }

  getSafe(property) {
    try {
      return this.activity[property];
    } catch (e) {
      return undefined;
    }
  }

  setSafe(property, value) {
    try {
      this.activity[property] = value;
    } catch (e) {
      // return undefined;
    }
  }

  formatDate(dateString) {
    let date_out = '';
    let date = new Date(dateString);
    date_out += date.getFullYear();
    date_out += '-';
    date_out += ((date.getMonth() + 1) <= 9) ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
    date_out += '-';
    date_out += (date.getDate() <= 9) ? '0' + date.getDate() : date.getDate();
    return date_out;
  }

  deleteActivityImage(image_id, imageObj) {
    let _self = this;
    if (image_id)
      this.apiService.get('activityimages/deleteactivityimage',
        {image_id: image_id, activity_id: this.getSafe('id')},
        (err, response) => {
          if (err) return;
          _self.toastrService.success('Image deleted.');
          let i = 0;
          for (const value of _self.activity_gallery) {
            if (value.id === image_id) _self.activity_gallery.splice(i, 1);
            i++;
          }
          _self.gallery_img_length = _self.checkaUploadedVal(60);
        });
  }

  getActivityImages() {

    let _self = this;
    if (this.getSafe('id') && !isNaN(this.getSafe('id'))) {
      this.apiService.get('activityimages/activityImages', {activity_id: this.getSafe('id')}, (err, response) => {
        if (err) return;

        _self.activity_gallery = response.data.map(function (image) {
          return {src: image.path, thumb: image.thumb, type: image.imagetype, subHtml: '', id: image.id};
        });

        _self.coverImage = _self.getImageByType(61);
        _self.galleryImages = _self.getImageByType(60);
        _self.gallery_img_length = _self.checkaUploadedVal(60);
      });
    }

  };

  initializeActivityAddressMap() {
    let _self = this;
    const autocomplete = new google.maps.places.Autocomplete(this.activity_address_pin.nativeElement);

    // Init map with either saved lat, lng or india centre lat, lng
    this.viewActivityAddressMap(this.activity_lat.nativeElement.value || 21.7679, this.activity_lon.nativeElement.value || 78.8718);

    google.maps.event.addListener(autocomplete, 'place_changed', function () {
      let place = autocomplete.getPlace();
      _self.activity.address = place.formatted_address;
      _self.activity.lat = place.geometry.location.lat();
      _self.activity.lon = place.geometry.location.lng();
      _self.activity.city = (_self.getSelectedValue('city', place, 'locality')) ?
        _self.getSelectedValue('city', place, 'locality') :
        _self.getSelectedValue('city', place, 'administrative_area_level_2');
      _self.activity.state = _self.getSelectedValue('state', place, 'administrative_area_level_1');
      _self.activity.locality = _self.getValueFrmFormatterAdd(place, 'political');
      _self.activity.pincode = _self.getValueFrmFormatterAdd(place, 'postal_code');

      _self.activity_address_pin.nativeElement.addEventListener('change');
      _self.activity_address_pin.nativeElement.dispatchEvent(new Event('change', {'bubbles': false}));
      _self.viewActivityAddressMap(place.geometry.location.lat(), place.geometry.location.lng());
    });
  }

  viewActivityAddressMap(lat, lng) {
    let _self = this;
    let infowindow = new google.maps.InfoWindow();
    let gLatLng = new google.maps.LatLng(lat, lng);
    let myOptions = {
      zoom: 14,
      center: gLatLng,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    const map = new google.maps.Map(this.ActivityMap.nativeElement, myOptions);

    let marker = new google.maps.Marker({
      draggable: true,
      position: gLatLng,
      map: map,
      title: 'Activity Location'
    });

    let formatted_address = 'Locate on Map';
    infowindow.setContent(formatted_address);
    infowindow.open(map, marker);

    google.maps.event.addListener(marker, 'dragend', function (event) {
      geocodePosition(marker.getPosition());
      _self.activity_address_pin.nativeElement.dispatchEvent(new Event('change', {'bubbles': false}));
    });

    function geocodePosition(pos) {
      let geocoder = new google.maps.Geocoder();
      geocoder.geocode({latLng: pos}, function (responses) {
        if (responses && responses.length > 0) {
          console.log('selected address', responses);

          let place = responses[0];
          if (place) {
            _self.activity.address = place.formatted_address;
            _self.activity.lat = place.geometry.location.lat();
            _self.activity.lon = place.geometry.location.lng();
            _self.activity.city = (_self.getSelectedValue('city', place, 'locality')) ?
              _self.getSelectedValue('city', place, 'locality') :
              _self.getSelectedValue('city', place, 'administrative_area_level_2');
            _self.activity.state = _self.getSelectedValue('state', place, 'administrative_area_level_1');
            _self.activity.locality = _self.getValueFrmFormatterAdd(place, 'political');
            _self.activity.pincode = _self.getValueFrmFormatterAdd(place, 'postal_code');
          }
          _self.activity_address_pin.nativeElement.dispatchEvent(new Event('change', {'bubbles': false}));
        }
      });
    }
  }

  getValueFrmFormatterAdd(place, properties) {
    let requiredObj = _.find(place.address_components, function (value: any) {
      return _.includes(value.types, properties);
    });

    if (typeof requiredObj === 'object') {
      return requiredObj.long_name || requiredObj.short_name;
    } else {
      return null;
    }
  }

  getSelectedValue(type, place, properties) {
    let requiredObj = {};

    let fetched_value = this.getValueFrmFormatterAdd(place, properties);

    if (type === 'city') {
      requiredObj = _.find(this.city.data, function (value) {
        return value['text'] === fetched_value;
      });
    }

    if (type === 'state') {
      requiredObj = _.find(this.state.data, function (value) {
        return value['text'] === fetched_value;
      });
    }

    if (typeof requiredObj === 'object') {
      return requiredObj['id'] || null;
    } else {
      return null;
    }
  }

  openPreview(activity) {
    let _self = this;
    let webUrl = this.apiService.WEBSITE_URL;

    let city_Obj = _.filter(this.city.data, function (o) {
      return o['id'] == _self.getSafe('city');
    });

    let city_name = (city_Obj.length > 0 && city_Obj[0]['text']) ? city_Obj[0]['text'] : '';

    if (webUrl) {
      let title = (activity.title && activity.title.trim() !== '') ?
        this.wordLimitTo(this.slugify(activity.title.trim()), 30) : 'summercamp';

      title = (title.startsWith('-')) ? title.substr(1, (title.length - 1)) : title;
      title = (title.endsWith('-')) ? title.substr(0, (title.length - 1)) : title;

      let url = title + '-' + this.wordLimitTo(this.slugify(this.getSafe('locality').trim()), 30) + '-' + this.slugify(activity.id);
      window.open(webUrl + '/summercamps/' + this.slugify(city_name) + '/' + url, '_blank');
    } else {
      this.toastrService.error('Preview Failed: Try republishing summercamp Or Contact Us');
    }

  };

  deleteActivity() {

    this.setSafe('status', !this.getSafe('status'));
    if (!this.getSafe('status')) {
      let isToAct = confirm('Are you want to delete this summer camp ?');
      if (!isToAct) {
        this.setSafe('status', !this.getSafe('status'));
        return;
      }
    }

    this.createOrUpdateActivity();

  }

  setActivitiesTags() {
    let _self = this;
    let post_data = {activity_Id: this.getSafe('id'), api_key: this.apiService.API_KEY};
    this.apiService.extGet(`${this.apiService.WEBSITE_URL}/admin/activity/server/routes/tags_count_update.php`,
      post_data, (err, response) => {
        console.log(err, response);
      });
  }

  reverseGeocodePosition(pos) {
    let _self = this;
    let geocode = new google.maps.Geocoder();
    geocode.geocode({latLng: pos}, function (responses) {
      if (responses && responses.length > 0) {
        console.log('responses address', responses);

        let place = responses[0];
        if (place) {
          // _self.activity.address = place.formatted_address;
          // _self.activity.lat = place.geometry.location.lat();
          // _self.activity.lon = place.geometry.location.lng();
          _self.activity.city = (_self.getSelectedValue('city', place, 'locality')) ?
            _self.getSelectedValue('city', place, 'locality') :
            _self.getSelectedValue('city', place, 'administrative_area_level_2');
          _self.activity.state = _self.getSelectedValue('state', place, 'administrative_area_level_1');
          _self.activity.locality = _self.getValueFrmFormatterAdd(place, 'political');
          _self.activity.pincode = _self.getValueFrmFormatterAdd(place, 'postal_code');
        }
      }
    });
  }

  saveActivity() {
    this.activity.publish = 0;
    this.createOrUpdateActivity();
  }

  getSchoolInfo() {
    let _self = this;
    this.apiService.get('schools/0', null, function (err, response) {
      const school = response.data;
      _self.setSafe('address', school.address1);
      _self.setSafe('city', school.cityid);
      _self.setSafe('state', school.state);
      _self.setSafe('locality', school.locality);
      _self.setSafe('lat', school.slat);
      _self.setSafe('lon', school.slon);
      _self.setSafe('pincode', school.pincode);
    });
  }
}
