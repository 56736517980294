import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {ModalModule} from 'ngx-bootstrap/modal';

import {EasyPieChartDirective} from './directives/easy-pie-chart/easy-pie-chart.directive';
import {JqvMapDirective} from './directives/jqvmap/jqvmap.directive';
import {FlotDirective} from './directives/flot/flot.directive';
import {SparklineDirective} from './directives/sparklines/sparkline.directive';
import {AutosizeDirective} from './directives/autosize/autosize.directive';
import {InputFloatDirective} from './directives/input-float/input-float.directive';
import {CustomDatePipe} from './pipes/custom-date.pipe';

import {TodoListsComponent} from './components/widgets/todo-lists/todo-lists.component';
import {PieChartsComponent} from './components/widgets/pie-charts/pie-charts.component';
import {RandomPostComponent} from './components/widgets/random-post/random-post.component';
import {RecentPostsComponent} from './components/widgets/recent-posts/recent-posts.component';
import {VisitorsComponent} from './components/widgets/visitors/visitors.component';
import {PastDaysComponent} from './components/widgets/past-days/past-days.component';
import {PhotosComponent} from './components/widgets/photos/photos.component';
import {TasksComponent} from './components/widgets/tasks/tasks.component';
import {ContactsComponent} from './components/widgets/contacts/contacts.component';
import {RatingsComponent} from './components/widgets/ratings/ratings.component';
import {ProfileComponent} from './components/widgets/profile/profile.component';
import {RecentSignupsComponent} from './components/widgets/recent-signups/recent-signups.component';
import {ErrorComponent} from './components/error/error.component';
import {PricingComponent} from '../auth/pricing/pricing.component';
import {ActivityComponent as SharedActivityComponent} from './components/activity/activity.component';
import {HomeComponent as SharedActivityHomeComponent} from './components/activity/home/home.component';
import {ApplicationsComponent as SharedApplicationsComponent} from './components/activity/applications/applications.component';
import {DetailComponent as SharedActivityDetailComponent} from './components/activity/detail/detail.component';
import {SchoolRegisterComponent} from '../admin/school-register/school-register.component';
import {AssociateSchoolComponent} from '../admin/associate-school/associate-school.component';
import {ActivityRegisterComponent} from '../admin/activity-register/activity-register.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TagInputModule} from 'ngx-chips';
import {ImageUploadModule} from './modules/ng2-image-upload/image-upload.module';
import { Select2Module } from './modules/ng2-select2/ng2-select2.moduel';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import {PaginationModule} from 'ngx-bootstrap/pagination';

import {DataTablesModule} from 'angular-datatables';
import {TabsComponent} from '../pages/components/tabs/tabs.component';
import {AccordionModule} from 'ngx-bootstrap/accordion';

// import { DropzoneDirective } from './directives/dropzone/dropzone.directive';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        Select2Module,
        FormsModule,
        ReactiveFormsModule,
        TagInputModule,
        PaginationModule.forRoot(),
        ImageUploadModule.forRoot(),
        ModalModule.forRoot(),
        TabsModule,
        DataTablesModule,
        AccordionModule.forRoot()
    ],
  declarations: [
    // Directives
    EasyPieChartDirective,
    JqvMapDirective,
    FlotDirective,
    SparklineDirective,
    AutosizeDirective,
    // DropzoneDirective,
    InputFloatDirective,

    // Pipe
    CustomDatePipe,

    // Components
    PastDaysComponent,
    TodoListsComponent,
    PieChartsComponent,
    RandomPostComponent,
    RecentPostsComponent,
    VisitorsComponent,
    PhotosComponent,
    TasksComponent,
    ContactsComponent,
    RatingsComponent,
    TabsComponent,
    ProfileComponent,
    RecentSignupsComponent,
    ErrorComponent,
    PricingComponent,
    SharedActivityComponent,
    SharedActivityHomeComponent,
    SharedActivityDetailComponent,
    SharedApplicationsComponent,
    SchoolRegisterComponent,
    AssociateSchoolComponent,
    ActivityRegisterComponent
  ],

  exports: [
    // Module
    ModalModule,

    // Directives
    EasyPieChartDirective,
    JqvMapDirective,
    FlotDirective,
    SparklineDirective,
    AutosizeDirective,
    // DropzoneDirective,
    InputFloatDirective,
    ImageUploadModule,

    // Pipe
    CustomDatePipe,

    // Components
    PastDaysComponent,
    TodoListsComponent,
    PieChartsComponent,
    RandomPostComponent,
    RecentPostsComponent,
    VisitorsComponent,
    PhotosComponent,
    TasksComponent,
    ContactsComponent,
    RatingsComponent,
    ProfileComponent,
    RecentSignupsComponent,
    PricingComponent,
    SharedActivityComponent,
    SharedActivityHomeComponent,
    SharedActivityDetailComponent,
    SharedApplicationsComponent,
    ActivityRegisterComponent,
    FormsModule, ReactiveFormsModule,
    TagInputModule,
    PaginationModule,
    Select2Module,
    BsDropdownModule,
    TabsModule,
    ModalModule,
    BsDatepickerModule,
    TimepickerModule,
    DataTablesModule,
    AccordionModule
  ]
})

export class SharedModule {
}
