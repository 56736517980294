import {NgModule} from '@angular/core';
import {BrowserModule, Meta, Title} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserXhr} from '@angular/http';
import {HttpClientModule} from '@angular/common/http';
import {NgProgressModule, NgProgress as NgProgress} from 'ngx-progressbar';

import {Routing} from './app.routing';
import {AppComponent} from './app.component';
import {SharedService} from './shared/services/shared.service';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {SharedModule} from './shared/shared.module';
import {HeaderModule} from './layout/header/header.module';
import {ToastrModule} from 'ngx-toastr';
import {ApiService} from './shared/services/api.service';
import {AuthGuard} from './auth/auth.guard';
import {DataService} from './shared/services/data.service';
import {NgProgressHttpModule} from 'ngx-progressbar/http';
import {NgProgressRouterModule} from 'ngx-progressbar/router';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    NgProgressModule.withConfig({
        color: '#e51814',
        thick: true
    }), 
    NgProgressHttpModule,
    NgProgressRouterModule,
    ToastrModule.forRoot(),
    Routing,
    SharedModule,
    HeaderModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    SharedService,
    Meta, Title,
    AuthGuard,
    ApiService,
    DataService,
    NgProgress,
    {provide: BrowserXhr},
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}
