<div
  fileDrop
  [accept]="supportedExtensions"
  (fileOver)="onFileOver($event)"
  (fileDrop)="onFileChange($event)"
  class="{{cssClass}}"
  [ngClass]="{'img-ul-file-is-over': fileOver}"
  [ngStyle]="style?.layout"
>
  <div class="img-ul-file-upload img-ul-hr-inline-group">
    <label class="img-ul-upload img-ul-button" [ngStyle]="style?.selectButton">
      <span [innerText]="buttonCaption"></span>
      <input
        type="file"
        [accept]="supportedExtensions"
        [disabled]="disabled"
        multiple (change)="onFileChange(input.files)"
        #input>
    </label>
    <label *ngIf="fileCounter > 0" class="img-ul-clear img-ul-button" (click)="deleteAll()"
           [ngStyle]="style?.clearButton">
      <span [innerText]="clearButtonCaption"></span>
    </label>
    <div class="img-ul-drag-box-msg" [innerText]="dropBoxMessage"></div>
  </div>

  <p class="img-ul-file-too-large" *ngIf="showFileTooLargeMessage" [innerText]="fileTooLargeMessage"></p>
  <p class="img-ul-file-too-large" *ngIf="showMaxUploadLimitMessage && files.length <= 0"
     [innerText]="maxUploadLimitMessage"></p>

  <div *ngIf="preview" class="img-ul-container img-ul-hr-inline-group" [ngStyle]="style?.previewPanel">
    <div
      class="img-ul-image"
      *ngFor="let file of files"
      [ngStyle]="{'background-image': 'url('+ file.src +')'}"
    >
      <div *ngIf="file.pending" class="img-ul-loading-overlay">
        <div class="img-ul-spinning-circle"></div>
      </div>
      <div *ngIf="!file.pending" class="img-ul-x-mark" (click)="deleteFile(file)">
        <span class="img-ul-close"></span>
      </div>
    </div>
  </div>
</div>
