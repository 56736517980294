import {Injectable} from '@angular/core';
import {ActivatedRoute, CanActivate, Router} from '@angular/router';
import {ApiService} from '../shared/services/api.service';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class AuthGuard implements CanActivate {


  constructor(private apiService: ApiService,
              private activatedRoute: ActivatedRoute,
              private toastrService: ToastrService,
              private router: Router) {
    this.apiService.init();
  }

  canActivate() {
    if (localStorage.getItem('user')) {
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page
    if (location.host.search('parent.') !== -1)
      this.router.navigate(['./parent/auth/login']);
    else if (location.host.search('school.') !== -1)
      this.router.navigate(['./school/auth/pricing']);
    else if (location.host.search('activity.') !== -1)
      this.router.navigate(['./activity/auth/login']);
    else
      this.router.navigate(['./error'], {relativeTo: this.activatedRoute});

    return false;
  }
}
