import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (new Date(value).toString() === 'Invalid Date') {
      return 'N/A';
    } else {
      return super.transform(new Date(value), args);
    }
  }
}
