<section class="content fixed-height">
  <form ngNativeValidate (submit)="onCreateSubmit()">
    <div class="card info-group">
      <div class="card-header-black">
        <h2 class="card-title">Create New School</h2>
      </div>
      <div class="card-block">

        <div class="row">

          <div class="col-sm-12">
            <div class="form-group form-group--float">
              <input name="name" type="text" [(ngModel)]="school_name" class="form-control" formControlFloat required>
              <label>School Name</label>
              <i class="form-group__bar"></i>
            </div>
          </div>

          <div class="col-sm-12">
            <div class="form-group form-group--select form-group--centered">
              <div class="select">
                <select [(ngModel)]="school_type" name="school_type" class="form-control" required>
                  <option selected disabled value="">Select School Type</option>
                  <option *ngFor="let type of SchoolTypes" [value]="type.id">{{type.text}}</option>
                </select>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 text-center">
        <button type="submit" class="btn btn-lg btn-success center-block" >
          <i class="zmdi zmdi-edit"></i>&nbsp;&nbsp; CREATE
        </button>
      </div>
    </div>
  </form>
</section>
