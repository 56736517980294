<section class="content fixed-height">
  <div>
    <div class="col form-control-lg">
      <button class="btn btn-block btn-primary" (click)="createNewActivity()">Create New Activity</button>
    </div>
  </div>

  <div class="row">
    <div *ngFor="let activity of activities" class="col-sm-12 col-md-6 col-lg-4 mb-3 text-center">
      <div class="img-thumbnail text-center camp-card" (click)="getActivity(activity?.id)">
        <img class="img-fluid cover-pic" *ngIf="activity?.cover_pic" src="{{activity?.cover_pic}}">
        <img class="img-fluid cover-pic" *ngIf="!activity?.cover_pic" src="https://previews.123rf.com/images/kthyk1109/kthyk11091410/kthyk1109141000003/33102856-music-splash-color-graphic-with-dance-people-vector.jpg">

          <!--activity?.cover_pic-->
        <p class="float-status">
          <span *ngIf="activity?.publish" class="text-success">Live</span>
          <span *ngIf="!activity?.publish" class="text-danger">Not Live</span>
        </p>
          <p class="float-status float-right-status">
              <span>{{activity?.type}}</span>
          </p>
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="row">
            <h4 class="col-sm-12 col-md-12 col-lg-12 mt-2 text-truncate" data-toggle="tooltip"
                title="{{ activity?.title }}">{{activity?.title}}</h4>
            <label class="col-sm-12 col-md-12 col-lg-12">Start
              date: {{activity?.start_date|customDate:'mediumDate'}}</label>
            <div class="col-12">
              <hr class="m-2"/>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6 text-center text-success">
              Views {{(activity?.views) ? activity?.views : 0}}
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6 text-center text-primary">
              Impressions {{(activity?.impression_count) ? activity?.impression_count : 0}}
            </div>
              <div class="col-sm-12">{{activity?.tags}}</div>
            <button class="btn btn-sm btn-info btn-block mt-2" (click)="manageAct(activity?.id)">Manage
              Application
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>



