import { Component } from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {ApiService} from './shared/services/api.service';
import { Meta, Title } from '@angular/platform-browser';
declare const seo_info_title: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})

export class AppComponent {
    constructor(private router: Router, private apiService: ApiService,  private titleService: Title, private metaService: Meta) {
         if (true) {
           this.router.events.subscribe(event => {
              if (event instanceof NavigationEnd) {
                (<any>window).ga('set', 'page', event.urlAfterRedirects);
                (<any>window).ga('send', 'pageview');

                const url = event.url.split(/[?#]/)[0];
                if (seo_info_title && seo_info_title[url]) {
                    console.log('navigation 2 end in app component', event, seo_info_title[event.url]);
                    this.titleService.setTitle(seo_info_title[url]['title']);
                    this.metaService.updateTag({ name: 'description', content: seo_info_title[url]['description'] });
                }

              }
            });
         }
    }
}
