import { Injectable } from '@angular/core';

@Injectable()
export class DataService {

  private DATA = {};

  constructor() {}

  set(key, value) {
    this.DATA[key] = value;
  }

  get(key) {
    return this.DATA[key];
  }

}
