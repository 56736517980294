<div class="navigation-trigger hidden-xl-up" [class.navigation-trigger--toggled]="sidebarVisible" (click)="toggleSidebarVisibility()">
    <div class="navigation-trigger__inner">
        <i class="navigation-trigger__line"></i>
        <i class="navigation-trigger__line"></i>
        <i class="navigation-trigger__line"></i>
    </div>
</div>

<!--
{{ isSidebarVisible }}-->
