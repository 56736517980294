// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

// key_id: 'rzp_live_KVuWik4X4oLUGU', // rzp_live_KVuWik4X4oLUGU, rzp_test_z5OYsYy2GlRJaP
// key_secret: 'Wn7ejU03IkuwE5Fiuq96FwJe' // Wn7ejU03IkuwE5Fiuq96FwJe, 3MmSZSRsbKVmGillTtHoF5GX

export const environment = {
  production: true,
  website_url: 'https://local.edustoke.com',
  domainName: '.edustoke.com',
  api_key: 'ASDFASDFCJD7jdjenj3**jdndeje()kdj<>h78',
  activity_amount: 0,
  is_payment_live: 0,
  key_id_live: 'rzp_live_KVuWik4X4oLUGU',
  key_id_test: 'rzp_test_z5OYsYy2GlRJaP',
  key_secret_live: 'Wn7ejU03IkuwE5Fiuq96FwJe',
  key_secret_test: '3MmSZSRsbKVmGillTtHoF5GX'
};
