<div id="wrapper" class="en-wrapper">
    <section class="lp-module lp-plans" id="plans" (window:resize)="onResize()">

        <div class="container-fluid">
            <div class="row">
                <div class="col my-5" *ngIf="!paymentpage || schoolType">
                    <h2 class="text-center mb-5 font-weight-bold">
                        Give your school the prominence it deserves – Get your Subscription on edustoke
                    </h2>
                    <h6 *ngIf="!paymentpage" class="text-center">
                        Already have an account&nbsp;?&nbsp;
                        <a [routerLink]="['../login']" class="btn btn-info">
                            Login here
                        </a>
                    </h6>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="card mb-5">
                        <div class="card-header border-bottom">
                            <p class="h3 text-info">Subscription</p>
                            <p class="text-muted font-italic mb-0">Exclusive of GST as per the current slab of 18%</p>
                        </div>
                        <div class="card-body">
                            <tabset *ngIf="!isMobileDevice" [justified]="true">
                                <tab *ngFor="let pricing of pricingInfo" [heading]="pricing['plainText']">
                                    <div class="row no-gutters">
                                        <div class="col mb-3">
                                            <p class="h3 text-center my-3">{{pricing['plainText']}}</p>
                                            <div class="container">
                                                <div class="row mb-5">
                                                    <div class="col">
                                                        Edustoke is the country largest platform for search and
                                                        selection of school having been visited by over 10 million
                                                        parents. Each day thousands of parents use edustoke’s services
                                                        to make a decision about their child’s school. Subscription on
                                                        edustoke would not only enable your institution to be discovered
                                                        and considered by thousands of prospective parents, but it would
                                                        also help you to showcase your school to stand out from the
                                                        clutter to competition
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col border-right">
                                                        <div class="row">
                                                            <div class="col-12 text-center mb-5">
                                                                <p class="h2">&#8377; {{pricing['displayPrice']}}</p>
                                                                <p class="text-muted">billed yearly</p>
                                                                <button class="btn btn-primary px-3"
                                                                        (click)="paymentInit('paid', pricing['type'])">
                                                                    SIGN UP
                                                                </button>
                                                            </div>

                                                            <div class="col-12">
                                                                <ul *ngIf="false" class="features-list has-cta">
                                                                    <li>
                                                                        <strong>Page Analytics </strong>
                                                                    </li>
                                                                    <li>
                                                                        <ul>
                                                                            <li>
                                                                                Get count of number of total parents
                                                                                looking for
                                                                                your kind of school in the vicinity
                                                                            </li>
                                                                            <li>
                                                                                No. of parents who engaged by calling
                                                                                the
                                                                                school.
                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                    <li>
                                                                        <strong>Subscription of Premium page on Edustoke
                                                                            with </strong>
                                                                        <span class="features-list__item-tooltip"
                                                                              id="personal_tooltip_1"></span>
                                                                    </li>
                                                                    <li>
                                                                        <ul>
                                                                            <li>
                                                                                Ability to upload upto 25 photographs
                                                                            </li>
                                                                            <li>
                                                                                Ability to upload your school video
                                                                            </li>
                                                                            <li>
                                                                                Contact Data
                                                                            </li>
                                                                            <li>
                                                                                Key leadship profiles with photographs
                                                                            </li>
                                                                            <li>
                                                                                Show school infrastructure and
                                                                                facilities data
                                                                                with photographs
                                                                            </li>
                                                                            <li>
                                                                                Publish details of curriculum and
                                                                                teaching
                                                                                methodology
                                                                            </li>
                                                                            <li>
                                                                                Ability to clearly spell out school’s
                                                                                uniqueness
                                                                                to parents
                                                                            </li>
                                                                            <li>
                                                                                Dedicated section for awards and
                                                                                recognitions
                                                                            </li>
                                                                            <li>
                                                                                Admission Details
                                                                            </li>
                                                                            <li>
                                                                                Academic Performance
                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                </ul>

                                                                <ul class="features-list has-cta">
                                                                    <li>Access to all 100 content fields to tell your
                                                                        story effectively
                                                                    </li>
                                                                    <li>Upload 25 photographs and videos</li>
                                                                    <li>Get real time analytics to your page impressions
                                                                        and views by parents
                                                                    </li>
                                                                    <li>Access to leads based on your school fee range,
                                                                        type and geography
                                                                    </li>
                                                                    <li>Fully customizable online registration form with
                                                                        payment facility
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="row">
                                                            <div class="col-12 text-center mb-5">
                                                                <p class="h2" [ngClass]="(pricing['type']!=2?'':'d-none')">&#8377; 35000</p>
                                                                <p class="h2" [ngClass]="(pricing['type']==2?'':'d-none')">&#8377; 15000</p>
                                                                <p class="text-muted">billed yearly</p>
                                                                <button [disabled]="paymentpage"
                                                                        class="btn btn-primary px-3"
                                                                        (click)="paymentInit('free', pricing['type'])">
                                                                    SIGN UP
                                                                </button>
                                                            </div>

                                                            <div class="col-12">
                                                                <ul *ngIf="false" class="features-list has-cta">
                                                                    <li>
                                                                        <strong>Yearly subscription of your school page
                                                                            with</strong>
                                                                        <span class="features-list__item-tooltip">
              </span>
                                                                    </li>
                                                                    <li>
                                                                        <ul>
                                                                            <li>
                                                                                <!--<i class="zmdi zmdi-check"></i>&nbsp;&nbsp;-->
                                                                                Contact Data
                                                                            </li>
                                                                            <li>
                                                                                <!--<i class="zmdi zmdi-check"></i>&nbsp;&nbsp;-->
                                                                                Facilities Data
                                                                            </li>
                                                                            <li>
                                                                                <!--<i class="zmdi zmdi-check"></i>&nbsp;&nbsp;-->
                                                                                Admission Data
                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                    <li>
                                                                        <strong>Upload up to {{freeImageLimit}}
                                                                            photographs</strong>
                                                                        <span class="features-list__item-tooltip"></span>
                                                                    </li>
                                                                </ul>

                                                                <ul class="features-list has-cta">
                                                                    <li>Contact and basic data upload and modification facility</li>
                                                                    <li>Upload up to 5 photographs</li>
                                                                    <li>Fully customizable online registration form with payment facility</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12 border-top">
                                            <p class="text-center text-muted small pt-2 mb-0">
                                                GST calculated at 18% as per the current norms, subject to change as
                                                per government regulations.
                                            </p>
                                        </div>
                                    </div>
                                </tab>
                            </tabset>

                            <accordion id="mobilePricing" *ngIf="isMobileDevice" [closeOthers]="true"
                                       [isAnimated]="true">
                                <accordion-group *ngFor="let pricing of pricingInfo" [heading]="pricing['plainText']">
                                    <div class="row mb-3">
                                        <div class="col mb-3">
                                            <p class="h3 text-center my-3">{{pricing['plainText']}}</p>
                                            <div class="container">
                                                <div class="row mb-3">
                                                    <div class="col">
                                                        Contrary to popular belief, Lorem Ipsum is not simply random
                                                        text. It
                                                        has roots in a piece of classical Latin literature from 45 BC,
                                                        making it
                                                        over 2000 years old. Richard McClintock, a Latin professor at
                                                        Hampden-Sydney College in Virginia, looked up one of the more
                                                        obscure
                                                        Latin words, consectetur, from a Lorem Ipsum passage, and going
                                                        through
                                                        the cites of the word in classical literature, discovered the
                                                        undoubtable source. Lorem Ipsum comes from sections 1.10.32 and
                                                        1.10.33
                                                        of "de Finibus Bonorum et Malorum" (The Extremes of Good and
                                                        Evil) by
                                                        Cicero, written in 45 BC. This book is a treatise on the theory
                                                        of
                                                        ethics, very popular during the Renaissance. The first line of
                                                        Lorem
                                                        Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in
                                                        section
                                                        1.10.32.
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12 mb-3">
                                                        <div class="row">
                                                            <div class="col-12 text-center mb-3">
                                                                <p class="h2">&#8377; {{pricing['displayPrice']}}</p>
                                                                <p class="text-muted">billed yearly</p>
                                                                <button class="btn btn-primary px-3"
                                                                        (click)="paymentInit('free', pricing['type'])">
                                                                    SIGN UP
                                                                </button>
                                                            </div>

                                                            <div class="col-12">
                                                                <ul *ngIf="false" class="features-list has-cta">
                                                                    <li>
                                                                        <strong>Page Analytics </strong>
                                                                    </li>
                                                                    <li>
                                                                        <ul>
                                                                            <li>
                                                                                Get count of number of total parents
                                                                                looking for
                                                                                your kind of school in the vicinity
                                                                            </li>
                                                                            <li>
                                                                                No. of parents who engaged by calling
                                                                                the
                                                                                school.
                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                    <li>
                                                                        <strong>Subscription of Premium page on Edustoke
                                                                            with </strong>
                                                                        <span class="features-list__item-tooltip"></span>
                                                                    </li>
                                                                    <li>
                                                                        <ul>
                                                                            <li>
                                                                                Ability to upload upto 25 photographs
                                                                            </li>
                                                                            <li>
                                                                                Ability to upload your school video
                                                                            </li>
                                                                            <li>
                                                                                Contact Data
                                                                            </li>
                                                                            <li>
                                                                                Key leadship profiles with photographs
                                                                            </li>
                                                                            <li>
                                                                                Show school infrastructure and
                                                                                facilities data
                                                                                with photographs
                                                                            </li>
                                                                            <li>
                                                                                Publish details of curriculum and
                                                                                teaching
                                                                                methodology
                                                                            </li>
                                                                            <li>
                                                                                Ability to clearly spell out school’s
                                                                                uniqueness
                                                                                to parents
                                                                            </li>
                                                                            <li>
                                                                                Dedicated section for awards and
                                                                                recognitions
                                                                            </li>
                                                                            <li>
                                                                                Admission Details
                                                                            </li>
                                                                            <li>
                                                                                Academic Performance
                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                </ul>

                                                                <ul class="features-list has-cta">
                                                                    <li>Access to all 100 content fields to tell your
                                                                        story effectively
                                                                    </li>
                                                                    <li>Upload 25 photographs and videos</li>
                                                                    <li>Get real time analytics to your page impressions
                                                                        and views by parents
                                                                    </li>
                                                                    <li>Access to leads based on your school fee range,
                                                                        type and geography
                                                                    </li>
                                                                    <li>Fully customizable online registration form with
                                                                        payment facility
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 border-top pt-3">
                                                        <div class="row">
                                                            <div class="col-12 text-center mb-5">
                                                                <p class="h2">&#8377; 0</p>
                                                                <p class="text-muted">billed yearly</p>
                                                                <button [disabled]="paymentpage" s class="btn btn-primary px-3"
                                                                        (click)="paymentInit('free', schoolType)">
                                                                    SIGN UP
                                                                </button>
                                                            </div>

                                                            <div class="col-12">
                                                                <ul *ngIf="false" class="features-list has-cta">
                                                                    <li>
                                                                        <strong>Yearly subscription of your school page
                                                                            with</strong>
                                                                        <span class="features-list__item-tooltip"
                                                                              id="free_tooltip_1">
              </span>
                                                                    </li>
                                                                    <li>
                                                                        <ul>
                                                                            <li>
                                                                                <!--<i class="zmdi zmdi-check"></i>&nbsp;&nbsp;-->
                                                                                Contact Data
                                                                            </li>
                                                                            <li>
                                                                                <!--<i class="zmdi zmdi-check"></i>&nbsp;&nbsp;-->
                                                                                Facilities Data
                                                                            </li>
                                                                            <li>
                                                                                <!--<i class="zmdi zmdi-check"></i>&nbsp;&nbsp;-->
                                                                                Admission Data
                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                    <li>
                                                                        <strong>Upload up to {{freeImageLimit}}
                                                                            photographs</strong>
                                                                        <span class="features-list__item-tooltip"
                                                                              id="free_tooltip_2"></span>
                                                                    </li>
                                                                </ul>

                                                                <ul class="features-list has-cta">
                                                                    <li>Contact and basic data upload and modification facility</li>
                                                                    <li>Upload up to 5 photographs</li>
                                                                    <li>Fully customizable online registration form with payment facility</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12 border-top">
                                            <p class="text-center text-muted small pt-3">
                                                GST calculated at 18% as per the current norms, subject to change as per
                                                government regulations.
                                            </p>
                                        </div>
                                    </div>
                                </accordion-group>
                            </accordion>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>

    <!--Modal for VAD option-->
    <div class="modal fade" bsModal #vadModal="bs-modal" [config]="{backdrop: 'static'}" role="dialog"
         tabindex="-1" aria-labelledby="bs-modal" aria-hidden="true">
        <div class="modal-dialog modal-lg">

            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title heading">Select your lead subscription </h4>
                    <button type="button" class="close" data-dismiss="modal" (click)="vadModal.hide()">&times;</button>
                </div>
                <div class="modal-body ">

                    <div class="row boardingModal">
                        <div class="col-md-6">
                            <div class="gridModal active" (click)="paymentInit('paid-vad',3)">

                                <img src="https://images.cdn3.stockunlimited.net/clipart/customer-care-executive_1389325.jpg"
                                     class="imgBoard">
                                <h1 class="main-text">VAD</h1>
                                <p class="plaintext">Lorem Ipsum is simply dummy text of the printing and typesetting
                                    industry. Lorem
                                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen book.
                                    It has survived not only five centuries, but also the leap into electronic
                                    typesetting, remaining essentially unchanged. </p>

                            </div>

                        </div>
                        <div class="col-md-6">

                            <div class="gridModal" (click)="paymentInit('paid',3)">


                                <img src="https://image.freepik.com/free-vector/business-people-finishing-a-market-race_3446-681.jpg"
                                     class="imgBoard">
                                <h1 class="main-text">Leads</h1>
                                <p class="plaintext">Lorem Ipsum is simply dummy text of the printing and typesetting
                                    industry. Lorem
                                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen book.
                                    It has survived not only five centuries, but also the leap into electronic
                                    typesetting, remaining essentially unchanged. </p>

                            </div>

                        </div>

                    </div>


                </div>
                <div class="modal-footer">

                </div>
            </div>

        </div>
    </div>

    <!-- Modal to select pre-school lead package -->
    <div class="modal leadPackageModal fade" bsModal #preSchoolPackageModal="bs-modal" [config]="{backdrop: 'static'}"
         role="dialog"
         tabindex="-1" aria-labelledby="mySmallModalLabel" aria-hidden="true">
        <div class="modal-dialog">

            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left"> <!--<i class="fa fa-warning"></i>--> Select Lead Package</h4>
                    <button type="button" class="close pull-right" data-dismiss="modal"
                            (click)="preSchoolPackageModal.hide()">
                        &times;
                    </button>

                </div>
                <div class="modal-body">
                    <div class="model-1">
                        <div class="row">

                            <div class="col-md-4 inner"><h4 class="package-heading">5 Leads Package</h4><br/>
                                <p class="para-inner">Price: 450 Rs/Lead</p><br/>

                                <p class="para-2">Total: <code>2250 INR</code></p><br/>
                                <button class="btn btn-primary btn-sm" (click)="optLeadPackage(5)">Continue</button>
                                <br/>
                            </div>
                            <div class="col-md-4 inner"><h4 class="package-heading">10 Leads Package</h4><br/>
                                <p class="para-inner">Price: 400 Rs/Lead</p><br/>

                                <p class="para-2">Total: <code>4000 INR</code></p><br/>
                                <button class="btn btn-primary btn-sm" (click)="optLeadPackage(10)">Continue</button>
                            </div>
                            <br/>
                            <div class="col-md-4 inner"><h4 class="package-heading">20 Leads Package</h4><br/>
                                <p class="para-inner">Price: 325 Rs/Lead</p><br/>
                                <p class="para-2">Total: <code>6500 INR</code></p><br/>
                                <button class="btn btn-primary btn-sm" (click)="optLeadPackage(20)">Continue</button>
                            </div>
                            <br/>
                        </div>
                    </div>


                </div>
                <div class="modal-footer inner">
                    <button type="button" class="btn btn-default inner" data-dismiss="modal"
                            (click)="preSchoolPackageModal.hide()">Close
                    </button>
                </div>

            </div>

        </div>
    </div>

    <!--End Lead package modal -->

</div>


