import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {Headers} from '@angular/http';

import {ImageService} from './image.service';
import {Style} from './style';
import {UploadMetadata} from './before-upload.interface';

export class FileHolder {
  public pending = false;
  public serverResponse: { status: number, response: any };

  constructor(public src: string, public file: File) {
  }
}

@Component({
  selector: 'image-upload', templateUrl: './image-upload.component.html', styleUrls: ['./image-upload.component.css']
})
export class ImageUploadComponent implements OnInit, OnChanges {

  files: FileHolder[] = [];
  fileCounter = 0;
  fileOver = false;
  showFileTooLargeMessage = false;
  documentFormat = 'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';

  @Input() beforeUpload: (UploadMetadata) => UploadMetadata | Promise<UploadMetadata> = data => data;
  @Input() buttonCaption = 'Select Images';
  @Input() formData: { [name: string]: any };
  @Input('class') cssClass = 'img-ul';
  @Input() clearButtonCaption = 'Clear';
  @Input() dropBoxMessage = 'Drop your images here!';
  @Input() fileTooLargeMessage: string;
  @Input() headers: Headers | { [name: string]: any };
  @Input() max = 100;
  @Input() maxFileSize: number = 5 * 1024 * 1024;
  @Input() preview = true;
  @Input() partName: string;
  @Input() style: Style;
  @Input('extensions') supportedExtensions: string[];
  @Input() url: string;
  @Input() withCredentials = false;
  @Input() uploadedFiles: string[] | Array<{ url: string, fileName: string, blob?: Blob }> = [];
  @Input() showMaxUploadLimitMessage = false;
  @Input() maxUploadLimitMessage: string;
  @Input() disabled: boolean;
  @Output() removed: EventEmitter<FileHolder> = new EventEmitter<FileHolder>();
  @Output() uploadStateChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() uploadFinished: EventEmitter<FileHolder> = new EventEmitter<FileHolder>();
  onFileOver = (isOver) => this.fileOver = isOver;
  @ViewChild('input') private inputElement: ElementRef;
  private pendingFilesCounter = 0;
  private countRemainingSlots = () => {
    console.log('countRemainingSlots', this.max, this.fileCounter);
    return (this.max - this.fileCounter);
  }

  constructor(private imageService: ImageService) {
  }

  ngOnInit() {
    // console.log('max images=', this.max);
    if (!this.fileTooLargeMessage) {
      this.fileTooLargeMessage = 'Error:' +
        (this.maxFileSize ? (' Maximum allowed file size is ' + this.maxFileSize / (1 * 1024 * 1024) + ' MB.') : '');
    }
    if (!this.maxUploadLimitMessage) {
      this.maxUploadLimitMessage = 'Info: You have reached maximum allowed files upload limit.';
    }
    this.supportedExtensions = this.supportedExtensions ? this.supportedExtensions : ['image/*'];
  }

  deleteAll() {
    this.files.forEach(f => this.removed.emit(f));
    this.files = [];
    this.fileCounter = 0;
    this.inputElement.nativeElement.value = '';
  }

  deleteFile(file: FileHolder): void {
    let index = this.files.indexOf(file);
    this.files.splice(index, 1);
    this.fileCounter--;
    this.inputElement.nativeElement.value = '';
    this.removed.emit(file);
  }

  ngOnChanges(changes) {
    // console.log('max images=', this.max);
    if (changes.uploadedFiles && changes.uploadedFiles.currentValue && changes.uploadedFiles.currentValue.length > 0) {
      this.processUploadedFiles();
    }
    // if (this.showMaxUploadLimitMessage) {
    //   this.showMaxUploadLimitMessage = true;
    //   this.disabled = this.showMaxUploadLimitMessage;
    // }
  }

  onFileChange(files: FileList) {
    let remainingSlots = this.countRemainingSlots();
    let filesToUploadNum = files.length > remainingSlots ? remainingSlots : files.length;

    if (this.url && filesToUploadNum != 0) {
      this.uploadStateChanged.emit(true);
    }

    this.fileCounter += filesToUploadNum;
    this.showFileTooLargeMessage = false;
    this.uploadFiles(files, filesToUploadNum);
  }

  private onResponse(response, fileHolder: FileHolder) {
    fileHolder.serverResponse = response;
    fileHolder.pending = false;

    this.uploadFinished.emit(fileHolder);

    if (--this.pendingFilesCounter == 0) {
      this.uploadStateChanged.emit(false);
    }
  }

  private processUploadedFiles() {
    for (let i = 0; i < this.uploadedFiles.length; i++) {
      let data: any = this.uploadedFiles[i];

      let fileBlob: Blob, file: File, fileUrl: string;

      if (data instanceof Object) {
        fileUrl = data.url;
        fileBlob = (data.blob) ? data.blob : new Blob([data]);
        file = new File([fileBlob], data.fileName);
      } else {
        fileUrl = data;
        fileBlob = new Blob([fileUrl]);
        file = new File([fileBlob], fileUrl);
      }

      this.files.push(new FileHolder(fileUrl, file));
      ++this.fileCounter;
    }
    // console.log('processUploadedFiles', this.uploadedFiles.length, this.files.length);

  }

  private async uploadFiles(files: FileList, filesToUploadNum: number) {
    for (let i = 0; i < filesToUploadNum; i++) {
      const file = files[i];
      // if (this.showMaxUploadLimitMessage) {
      //   break;
      // }

      if (this.maxFileSize && file.size > this.maxFileSize) {
        this.fileCounter--;
        this.inputElement.nativeElement.value = '';
        this.showFileTooLargeMessage = true;
        continue;
      }

      let beforeUploadResult: UploadMetadata = await this.beforeUpload({file, url: this.url, abort: false});
      beforeUploadResult.formData = this.formData;

      if (beforeUploadResult.abort) {
        this.fileCounter--;
        this.inputElement.nativeElement.value = '';
        continue;
      }

      const img = document.createElement('img');
      img.src = window.URL.createObjectURL(beforeUploadResult.file);

      const reader = new FileReader();
      reader.addEventListener('load', (event: any) => {
        const fileHolder: FileHolder = new FileHolder(event.target.result, beforeUploadResult.file);
        this.uploadSingleFile(fileHolder, beforeUploadResult.url, beforeUploadResult.formData);
        this.files.push(fileHolder);
      }, false);
      reader.readAsDataURL(beforeUploadResult.file);
    }
  }

  private uploadSingleFile(fileHolder: FileHolder, url = this.url, customForm?: { [name: string]: any }) {
    if (url) {
      this.pendingFilesCounter++;
      fileHolder.pending = true;

      this.imageService
        .postImage(this.url, fileHolder.file, this.headers, this.partName, customForm, this.withCredentials)
        .subscribe(response => this.onResponse(response, fileHolder), error => {
          this.onResponse(error, fileHolder);
          this.deleteFile(fileHolder);
        });
    } else {
      this.uploadFinished.emit(fileHolder);
    }
  }

}
