import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {ApiService} from '../../../shared/services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {WindowRef} from '../../../shared/services/WindowRef';

@Component({
  selector: 'header-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  providers: [WindowRef]
})

export class SearchComponent implements OnInit, OnChanges {
  @Input() searchStatus = false;
  @Output() searchStatusChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  searchValue = '';

  // School Suggestion
  isSuggestionOpen = false;
  hasSuggestionSelected = false;
  schoolName = '';
  school_id: number;
  school_type = '';
  schoolSuggestions = [];

  closeSearch() {
    this.searchStatus = false; // Close the search block
    this.searchStatusChange.emit(this.searchStatus);
    this.searchValue = null; // Empty the search field
    this.schoolSuggestions = [];
  }

  constructor(private apiService: ApiService,
              private activatedRoute: ActivatedRoute,
              private toastrService: ToastrService,
              private router: Router, private winref: WindowRef) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes) {
    this.searchStatus = changes.searchStatus.currentValue;
  }

  /*
   * User changed school name, reset values;
   */
  getSchoolSuggestions() {
    this.hasSuggestionSelected = false;
    this.school_id = null;
    if (!this.schoolName) {
      this.schoolSuggestions = [];
      return;
    }

    let _self = this;
    this.isSuggestionOpen = true;
    this.apiService.get('schools/name_suggestion', {name: this.schoolName, allschool: true}, function (err, res) {
      if (!err) _self.schoolSuggestions = res.data.schools;
    });

  }

  onSchoolSuggestionSelected(suggestion: any) {
    this.hasSuggestionSelected = true;
    this.schoolName = suggestion.name;
    this.school_id = suggestion.id;
    this.school_type = suggestion.typeid;
    this.isSuggestionOpen = false;
    this.schoolSuggestions = [];
    if (suggestion.role === 'activity') {

      localStorage.setItem('activity_owner', suggestion.id);
      sessionStorage.setItem('current_role', suggestion.role);

      if (localStorage.getItem('user')) {
        let user;
        try {
          user = JSON.parse(localStorage.getItem('user'));
        } catch (e) {
          user = null;
        }
        if (user) {
          user['owner'] = suggestion.id;
          try {
            user = JSON.stringify(user);
          } catch (e) {
            user = null;
          }
          if (user) {
            localStorage.setItem('user', user);
          }
        }
      }
      this.router.navigate(['activity/home']);
      if (location.pathname.includes('activity')) {
        location.reload();
      }
    } else {
      sessionStorage.setItem('current_role', suggestion.role);
      localStorage.removeItem('activity_owner');
      this.updateAdminSchoolId(this.school_id);
      /*this.router.navigate(['school/info']);
      if (location.pathname.includes('school')) {
        location.reload();
      }*/
    }

  }

  updateAdminSchoolId(school_id) {
    let self = this;
    this.apiService.put('admin/updateschool', {school_id: school_id}, function (err, res) {
      if (!err && res.code === 'OK') {
        sessionStorage.setItem('school_url', '');
        sessionStorage.setItem('admin_school_id', school_id);
        let current_path = self.winref.nativeWindow.location.pathname;
        self.router.navigate(['school/info']);
        location.reload();
      }
      else self.toastrService.error('An error occurred!');
    });
  }
}
