import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../shared/services/api.service';
import {ToastrService} from 'ngx-toastr';
import {WindowRef} from '../../shared/services/WindowRef';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
  providers: [WindowRef]
})
export class PricingComponent implements OnInit {
  @Output() paymentClicked: EventEmitter<Object> = new EventEmitter();
  private role: string;
  public paymentpage: boolean;
  public schoolType = 0;
  public pricingInfo: any;
  public freeImageLimit: number;
  public paidImageLimit: number;
  public queryString: string;
  public urlParams = new URLSearchParams();
  public schoolName: string;
  public schoolId: string;
  public schoolTypeid: string;
  public isMobileDevice: boolean;

  constructor(private activatedRoute: ActivatedRoute, private apiService: ApiService, private router: Router,
              private toastrService: ToastrService, private windowRef: WindowRef) {

    // this.schoolType = parseInt(sessionStorage.getItem('schoolTypeId'));

    this.freeImageLimit = 5;
    this.paidImageLimit = 25;
  }

  ngOnInit() {
      this.isMobileDevice = window.screen.width <= 768;
      this.role = this.activatedRoute.parent.snapshot.params['role'];
      this.paymentpage = this.activatedRoute.snapshot.url[0].path === 'payment';

      this.getSubscriptionPricing();
      this.claimSchoolFetchParams();
      this.loadCoverInfo();
  }

    onResize() {
        this.isMobileDevice = window.screen.width <= 768;
    }

  getSubscriptionPricing() {
      let _pricingInfo = ApiService.getSubscriptionPricing();
      let schoolTypeId: any = sessionStorage.getItem('schoolTypeId') || '0';
      schoolTypeId = parseInt(schoolTypeId);

      this.pricingInfo = this.paymentpage ? _pricingInfo.filter(obj => obj.type === schoolTypeId) : _pricingInfo;

      console.log({schoolTypeId}, this.paymentpage, this.pricingInfo);

      // this.pricingInfo = _.orderBy(this.pricingInfo, ['price', 'type'], ['asc', 'asc']);
  }

  navigateToRegister(plan, schoolType) {
    if (!this.paymentpage) {
      localStorage.setItem('registerPlan', plan);
      if (this.queryString && this.schoolName) {
        this.router.navigate([this.role + '/auth/register'],
          {queryParams: {id: this.schoolId, type: schoolType, name: this.schoolName}});
      } else {
        this.router.navigate([this.role + '/auth/claim'], {queryParams: {type: schoolType}});
      }
    }
  }

  paymentInit(plan, schoolType) {

    if (this.paymentpage) {
      this.paymentClicked.emit({plan: plan, schoolTypeId: schoolType});
    } else {
      this.navigateToRegister(plan, schoolType);
    }
  }

  loadCoverInfo() {
    let self = this;
    this.apiService.get('schools/coverinfo', null, function (err, response) {
      if (!err && response.data) {

        if (localStorage.getItem('user')) {
          let user;
          try {
            user = JSON.parse(localStorage.getItem('user'));
          } catch (e) {
            user = null;
          }
          if (user) {
            user['owner'] = response.data.owner;
            try {
              user = JSON.stringify(user);
            } catch (e) {
              user = null;
            }
            if (user) {
              localStorage.setItem('user', user);
            }
          }
        }

        if (self.urlParams && !self.urlParams.has('typeid')) {
          self.schoolType = parseInt(response.data.school_typeid);
          sessionStorage.setItem('schoolTypeId', self.schoolType.toString());
        }

      }
    });
  }

  claimSchoolFetchParams() {
    this.queryString = this.windowRef.nativeWindow.location.search;
    if (this.queryString) {
      this.urlParams = new URLSearchParams(this.queryString);
      if (this.urlParams.has('id')) {
        this.schoolId = this.urlParams.get('id');
      }
      if (this.urlParams.has('typeid')) {
        this.schoolTypeid = this.urlParams.get('typeid');
        this.schoolType = parseInt(this.schoolTypeid);
      }
      if (this.urlParams.has('type')) {
        this.schoolTypeid = this.urlParams.get('type');
        this.schoolType = parseInt(this.schoolTypeid);
      }
      if (this.urlParams.has('name')) {
        this.schoolName = this.urlParams.get('name');
      }
    }
  }

  optLeadPackage(lead_count) {
    localStorage.setItem('lead_package', lead_count);
    this.paymentInit('paid', 2);
  }


}
