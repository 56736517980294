<header class="header" [ngClass]="{header_theme__school: isSchool}">
    <navigation-trigger></navigation-trigger>

    <div class="header__logo hidden-sm-down">
        <h1><a href={{WEBSITE_URL}}><img class="img-fluid" src="../../assets/img/logo-new.png"></a></h1>
    </div>

    <ul class="header__nav">
        <!--<li *ngIf="exiting_credit">-->
        <!--<a [routerLink]="['/school/payment']" *ngIf="!paymentpage">₹ {{exiting_credit}}</a>-->
        <!--<a *ngIf="paymentpage">₹ {{exiting_credit}}</a>-->
        <!--</li>-->
        <li *ngIf="UserRole=='admin'"><a [routerLink]="" (click)="searchStatus = true; false"><i
                class="zmdi zmdi-search"></i></a></li>
        <li *ngIf="UserRole=='admin'">
            <header-search [(searchStatus)]="searchStatus"></header-search>
        </li>

        <li *ngIf="UserRole=='chainadmin' && chainCityList.length >1" class="dropdown chain-city">
            <select class="form-control " (change)="schoolChainCitySelected($event)">
                <option selected [value]="null">-- Select City --</option>
                <option *ngFor="let city of chainCityList" [value]="city.id">{{city.name}}
                </option>
            </select>
        </li>

        <li *ngIf="UserRole=='chainadmin'" class="dropdown chain-branch">
            <select class="form-control " [(ngModel)]="selectedSchoolChain" (change)="schoolChainSelected($event)">
                <option selected [ngValue]="null" disabled>-- Select School Branch --</option>
                <option *ngFor="let school of chainList" [value]="school.id">{{school.address1}}
                </option>
            </select>
        </li>


        <!--<li dropdown>
        <a dropdownToggle><i class="zmdi zmdi-email"></i></a>
        <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu&#45;&#45;block" role="menu">
        <div class="listview listview&#45;&#45;hover">
        <div class="listview__header">Messages</div>

        <a *ngFor="let message of messagesData" href="" class="listview__item">
        <img [src]="message.image" class="listview__img" alt="">

        <div class="listview__content">
        <h3 class="listview__heading">{{ message.name }}</h3>
        <p>{{ message.message }}</p>
        </div>
        </a>

        <a href="" class="view-more">See All Messages</a>
        </div>
        </div>
        </li>-->

        <!--Notifications messages-->
        <!--    <li dropdown>-->
        <!--        <a dropdownToggle><i class="zmdi zmdi-notifications"></i></a>-->

        <!--        <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu&#45;&#45;block" role="menu">-->
        <!--            <div class="listview listview&#45;&#45;hover">-->
        <!--                <div class="listview__header">Messages</div>-->

        <!--                <a *ngFor="let message of messagesData" href="" class="listview__item">-->
        <!--                    <img [src]="message.image" class="listview__img" alt="">-->

        <!--                    <div class="listview__content">-->
        <!--                        <h3 class="listview__heading">{{ message.name }}</h3>-->
        <!--                        <p>{{ message.message }}</p>-->
        <!--                    </div>-->
        <!--                </a>-->

        <!--                <a href="" class="view-more">See All Notifications</a>-->
        <!--            </div>-->
        <!--        </div>-->
        <!--    </li>-->


        <li dropdown>
            <a dropdownToggle (click)="getReviewNotification()">
                <i
                        class="zmdi zmdi-notifications zmdi-hc-fw mdc-text-light-blue {{(review_notification_count > 0)?'animated infinite pulse':''}}"></i>
                <span *ngIf="review_notification_count > 0"
                      class="zmdi animated infinite wobble zmdi-hc-fw badge badge-light mdc-text-light-blue"
                      style="position: absolute;top: -5px;right: 5px;;font-size: 100%;">
            {{review_notification_count}}
        </span>
            </a>

            <div *dropdownMenu
                 class="dropdown-menu dropdown-menu-right dropdown-menu&#45;&#45;block review-notification-panel"
                 role="menu">
                <div class="listview listview&#45;&#45;hover">
                    <div class="listview__header">Reviews</div>

                    <a *ngFor="let review of review_notification" class="listview__item"
                       (click)="navigateToReviews(review)">
                        <avatar class="listview__img" #avatar
                                [name]="review.user_name" [size]="40"
                                [background]="'#cf2c4b'" [displayType]="'circle'">
                        </avatar>

                        <div class="listview__content">
                            <h3 class="listview__heading text text-capitalize">{{ review.user_name }}</h3>
                            <p>{{ review.text }}</p>
                        </div>
                    </a>
                    <a class="view-more" [routerLink]="['review']">See All Reviews</a>
                </div>
            </div>
        </li>

        <li dropdown>
            <a dropdownToggle (click)="getRegistrationNotification()">
                <i class="zmdi zmdi-calendar-check zmdi-hc-fw mdc-text-light-blue {{(registration_notification_count > 0) ? 'animated infinite pulse':''}}"></i>
                <span *ngIf="registration_notification_count > 0"
                      class="zmdi animated infinite wobble zmdi-hc-fw badge badge-light mdc-text-light-blue"
                      style="position: absolute;top: -5px;right: 5px;;font-size: 100%;">
            {{registration_notification_count}}
        </span>
            </a>

            <div *dropdownMenu
                 class="dropdown-menu dropdown-menu-right dropdown-menu&#45;&#45;block review-notification-panel"
                 role="menu">
                <div class="listview listview&#45;&#45;hover">
                    <div class="listview__header">Registrations</div>

                    <a *ngFor="let registration of registration_notification" class="listview__item" target="_blank"
                       href="{{apiService.WEBSITE_URL }}/registration/student_application.php?id={{ registration.id}}">
                        <avatar class="listview__img" #avatar
                                [name]="registration.student_name" [size]="40"
                                [background]="'#cf2c4b'" [displayType]="'circle'">
                        </avatar>

                        <div class="listview__content">
                            <h3 class="listview__heading text text-capitalize">{{ registration.student_name }}</h3>
                            <p>A new registration happend for {{ registration.student_name }}</p>
                        </div>
                    </a>
                    <a class="view-more" [routerLink]="['admission/application']">See All Registration</a>
                </div>
            </div>
        </li>

        <!-- <li dropdown class="hidden-xs-down">
        <a dropdownToggle=""><i class="zmdi zmdi-view-headline"></i></a>

        <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu--block" role="menu">
        <div class="listview listview--hover">
        <div class="listview__header">Tasks</div>

        <a *ngFor="let task of tasksData" href="" class="listview__item">
        <div class="progress">
        <div [ngClass]="['progress-bar bg-'+ task.color]" [ngStyle]="{ 'width': task.completed+'%' }" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        </a>

        <a href="" class="view-more">View All Tasks</a>
        </div>
        </div>
        </li>

        <li dropdown class="hidden-xs-down">
        <a dropdownToggle=""><i class="zmdi zmdi-more-vert"></i></a>

        <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu--icon" role="menu">
        <a href="" class="dropdown-item"><i class="zmdi zmdi-fullscreen"></i> Toggle Fullscreen</a>
        <a href="" class="dropdown-item"><i class="zmdi zmdi-delete"></i> Clear Local Storage</a>
        <a href="" class="dropdown-item"><i class="zmdi zmdi-face"></i> Privacy Settings</a>
        <a href="" class="dropdown-item"><i class="zmdi zmdi-settings"></i> Other Settings</a>
        </div>
        </li> -->
        <li dropdown class="header__nav__profile">
            <!--<img dropdownToggle="" src="https://png.icons8.com/customer/color/200" alt="">-->

            <span dropdownToggle="" style="font-size: 30px;cursor: pointer;">
<i class="zmdi zmdi-city-alt"></i>
<i class="zmdi zmdi-caret-down"></i>
</span>

            <ul *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu--icon" role="menu">
                <li *ngIf="currentRole != 'parent'" routerLinkActive="active">
                    <a class="dropdown-item" [routerLink]="(currentRole == 'school')?'/school/me':'/activity/info'">
                        <i class="zmdi zmdi-account"></i> Profile
                    </a>
                </li>
                <!--<li class="dropdown-item"><i class="zmdi zmdi-account-circle"></i> Privlicy Settings</li>
                <li class="dropdown-item"><i class="zmdi zmdi-settings"></i> Settings</li>-->

                <li *ngIf="currentRole == 'school'" class="dropdown-item" (click)="previewSchool()"><i
                        class="zmdi zmdi-eye"></i>
                    Preview School
                </li>
                <li *ngIf="currentRole == 'school'" routerLinkActive="active"><a class="dropdown-item"
                                                                                 routerLink="/school/settings"><i
                        class="zmdi zmdi-settings"></i> Settings</a></li>
                <li class="dropdown-item" (click)="onLogout()"><i class="zmdi zmdi-power-off"></i> Logout</li>
            </ul>
        </li>
    </ul>

    <!--<header-search #search></header-search>-->
</header>
